import { Button } from '../../../Components/magicui/ui/button';
import { cn } from '../../../Components/magicui/lib/utils';
import { CheckIcon } from '@radix-ui/react-icons';
import { motion, useInView } from 'framer-motion';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowRequestModal } from '../../../../redux/reducers/generic/project';
import {
    selectSkillLevel,
    selectSow
} from '../../../../redux/reducers/generic/sow';
import SkillLevel from './skill_level';
import { useProject } from '../../../../context-providers/Project';

export const toHumanPrice = (price: number, decimals: number = 0) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(price / 100);
};

export function parseTotalHours(milestones) {
    try {
        const hours = JSON.parse(JSON.stringify(milestones)).reduce(
            (totalMilestoneHours, milestone) => {
                const storyHours = milestone.stories.reduce(
                    (totalStoryHours, story) => {
                        const taskHours = story.subTasks.reduce(
                            (totalTaskHours, task) =>
                                totalTaskHours + task.hours,
                            0
                        );
                        return totalStoryHours + taskHours;
                    },
                    0
                );
                return totalMilestoneHours + storyHours;
            },
            0
        );
        return hours;
    } catch (e) {
        return 0;
    }
}

export const getPrice = (skillLevel, hours, prices = null) => {
    if (prices) {
        const rates = {
            junior: prices[0] * 100,
            intermediate: prices[1] * 100,
            senior: prices[2] * 100
        };
        return hours * rates[skillLevel];
    } else {
        const rates = {
            junior: 5000,
            intermediate: 10000,
            senior: 20000
        };
        return hours * rates[skillLevel];
    }
};

const getFeatures = skillLevel => {
    const commonFeatures = ['Full Stack Codebase + Infrastructure Setup'];

    const skillSpecificFeatures = {
        junior: [
            '$50 per hour pricing',
            'Simple data security measures',
            'Basic documentation and testing'
        ],
        intermediate: [
            '$100 per hour pricing',
            'Enhanced data security and compliance',
            'Enhanced documentation and testing'
        ],
        senior: [
            '$200 per hour pricing',
            'Highest data security and compliance standards',
            'Comprehensive documentation and testing'
        ]
    };

    return [...skillSpecificFeatures[skillLevel], ...commonFeatures];
};

export default function PricingSection() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-100px' });
    let sowContent = useSelector(selectSow);
    const skillLevel = useSelector(selectSkillLevel);
    const dispatch = useDispatch();

    const hours = sowContent ? parseTotalHours(sowContent?.milestones) : 0;
    const price = getPrice(skillLevel, hours);
    const features = getFeatures(skillLevel);
    const { project } = useProject();

    const onRequestMeeting = () => {
        dispatch(setShowRequestModal(true));
    };

    return (
        <section ref={ref}>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1 }}
                className="mx-auto flex max-w-screen-xl flex-col gap-4 pt-8 md:px-8"
            >
                <div className="mx-auto max-w-5xl text-center">
                    <h2 className="text-5xl font-bold tracking-tight text-black dark:text-white sm:text-6xl">
                        Get it built by experts
                    </h2>

                    <p className="mt-6 text-xl leading-8 text-black/80 dark:text-white">
                        Connect with pre-vetted engineering teams to build your
                        product time and cost-effectively.
                    </p>
                </div>

                <div className="flex justify-center w-full">
                    <SkillLevel />
                </div>

                <div className="mx-auto grid w-full justify-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, ease: 'easeOut' }}
                        className={cn(
                            'relative flex max-w-[400px] flex-col gap-4 rounded-xl border-2 p-6',
                            'border-neutral-200 dark:border-neutral-700',
                            'text-black dark:text-white'
                        )}
                    >
                        <div>
                            <h2 className="text-xl font-semibold mb-2">
                                Product: {project.name}
                            </h2>
                            <p className="text-sm text-black/70 dark:text-white/70">
                                A customer-facing full-stack application.
                            </p>
                        </div>

                        <div className="text-4xl font-bold">
                            {toHumanPrice(price, 0)}
                            <span className="text-sm font-normal"> total</span>
                        </div>
                        <div className="flex items-center justify-center space-x-2 bg-white rounded-lg p-3 shadow-md transition-all duration-300 hover:shadow-lg hover:scale-102">
                            <span className="text-2xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                                {hours}
                            </span>
                            <span className="text-sm bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                                Estimated Hours
                            </span>
                            <span className="text-xl">⏳</span>
                        </div>

                        <Button
                            className={cn(
                                'w-full text-lg font-semibold',
                                'transition-all duration-300 hover:ring-2 hover:ring-primary hover:ring-offset-2'
                            )}
                            onClick={onRequestMeeting}
                        >
                            Request Meeting
                        </Button>

                        <hr className="border-t border-neutral-200 dark:border-neutral-700" />

                        {features && features.length > 0 && (
                            <ul className="space-y-2">
                                {features.map((feature, idx) => (
                                    <li
                                        key={idx}
                                        className="flex items-center gap-3 text-sm"
                                    >
                                        <CheckIcon className="h-5 w-5 text-green-500" />
                                        <span>{feature}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </motion.div>
                </div>
            </motion.div>
        </section>
    );
}
