import { useProject } from '../../../context-providers/Project';
import { useSelector, useDispatch } from 'react-redux';
import { Dock, DockIcon } from '../../Components/magicui/dock';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { chatEndingIndex, getChatEndingIndex } from '../../../constants';
import { toast } from 'sonner';
import { Icons } from './icons';
import { useEffect, useState } from 'react';
import { selectProjectInternalVersion } from '../../../redux/reducers/generic/project';
import { LeadGenToggle } from '../../EnterpriseHome/ClientProjects/ProjectsList/actions';

export const DockBar = ({ setFullScreen }) => {
    let architectureLoading = useSelector(selectProjectArchitectureLoading);

    let { chatHistory, project, pageType, setSearchParams, allSearchParams } =
        useProject();

    let currentIndex = getChatEndingIndex(chatHistory.data);

    let disableDock = false;
    if (currentIndex < chatEndingIndex) disableDock = true;
    if (project.skip) disableDock = false;

    let showPlaceholder = false;

    if (currentIndex < chatEndingIndex) showPlaceholder = true;
    if (project.skip) showPlaceholder = false;

    const projectInternalVersion = useSelector(selectProjectInternalVersion);

    const handleDockClick = (e, name) => {
        e.stopPropagation();
        if (disableDock) {
            toast.error(
                'Fill out the initial questions or fast forward the project.'
            );
            return;
        }

        if (name != 'chat') setFullScreen(false);
        if (name === 'code') {
            if (projectInternalVersion !== 2) {
                toast.error(
                    'Please migrate the project to use the new code generation tool.'
                );
                return;
            }
            if (architectureLoading) {
                toast.error('Architecture is loading, please wait.');
                return;
            }
        }
        setSearchParams({
            ...allSearchParams,
            pageType: name,
            sowType: name === 'sow' || name === 'roadmap' ? 'mvp' : null,
            sowPage:
                name === 'sow' || name === 'roadmap' ? 'Documentation' : null,
            graphType: name === 'code' ? 'frontend' : allSearchParams.graphType
        });
    };
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobileAndScreenSize = () => {
            setIsMobile(
                window.navigator.userAgent.match(
                    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
                ) !== null || window.innerWidth <= 768
            );
        };

        checkMobileAndScreenSize();
        window.addEventListener('resize', checkMobileAndScreenSize);

        return () => {
            window.removeEventListener('resize', checkMobileAndScreenSize);
        };
    }, []);

    const dockItems = [
        {
            name: 'chat',
            label: 'Project Discovery',
            icon: <Icons.projectDiscovery className="h-6 w-6 text-blue-400" />,
            className: '-pr-4',
            description:
                'Interactive chat interface for defining and refining project scope and requirements.'
        },
        {
            name: 'sow',
            label: 'Documentation',
            icon: <Icons.documentation className="h-7 w-7 text-yellow-400" />,
            className: `hover:${disableDock && 'blur-sm'}`,
            description:
                'Comprehensive documentation hub: combines Product Requirements Document (PRD), Statement of Work (SoW), and technical stack specifications.'
        },
        ...(isMobile
            ? [
                  {
                      name: 'graph',
                      label: 'Architecture',
                      icon: (
                          <Icons.architectureGraph className="h-7 w-7 text-indigo-400" />
                      ),
                      className: `hover:${disableDock && 'blur-sm'}`,
                      loading: architectureLoading && !showPlaceholder,
                      description: `Visual representation of the project's architectural structure and components.`
                  }
              ]
            : []),
        {
            name: 'roadmap',
            label: 'Roadmap',
            icon: (
                <Icons.roadmap className="h-8 w-8 mt-1 p-[2px] text-green-400" />
            ),
            className: `hover:${disableDock && 'blur-sm'}`,
            description:
                'Interactive Gantt chart for project timeline visualization and Jira/Linear integration capabilities.'
        },
        {
            name: 'code',
            label: 'Code Gen (Beta)',
            icon: (
                <Icons.code className="h-8 w-8 p-[4px] text-white rounded-full text-purple-400" />
            ),
            className: `hover:${disableDock && 'blur-sm'}`,
            description:
                'Automated UI/UX code generation tool for rapid frontend development.'
        }
    ];

    return (
        <Dock className="px-4 flex items-center">
            {dockItems.map(item => (
                <DockIcon
                    key={item.name}
                    onClick={e => handleDockClick(e, item.name)}
                    selected={pageType === item.name}
                    label={item.label}
                    loading={item.loading}
                    className={item.className}
                    description={item.description}
                >
                    <div className={item.className}>{item.icon}</div>
                </DockIcon>
            ))}
        </Dock>
    );
};
