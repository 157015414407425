import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import * as Tooltip from '@radix-ui/react-tooltip';
import { triggerLeadGenMeeting } from '../../../../redux/actions/enterprise/triggerLeadGenMeeting';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';

export const SendIntroductionEmail = ({ projectId }) => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const [isLeadGenLoading, setIsLeadGenLoading] = useState(false);
    const { enterpriseOrganization: orgData } = useEnterpriseOrganization();

    const handleTriggerLeadGenMeeting = async () => {
        setIsLeadGenLoading(true);
        console.log({ orgData });
        if (
            !orgData.calendlyLink ||
            !orgData.websiteURL ||
            !orgData.introductionLogo
        ) {
            toast.error(
                'Please set your Calendly link, website URL, and Company Logo in your Introduction Email Config before sending an introduction email.'
            );
            setIsLeadGenLoading(false);
            return;
        }
        try {
            const result = await dispatch(
                triggerLeadGenMeeting({
                    projectId,
                    enterpriseOrganizationId
                })
            );
            if (result) {
                // toast.success('Introduction email sent successfully!');
            } else {
                toast.error(
                    'Failed to send introduction email. Please try again.'
                );
            }
        } catch (error) {
            console.error('Error sending introduction email:', error);
            toast.error(
                'An error occurred while sending the introduction email.'
            );
        } finally {
            setIsLeadGenLoading(false);
        }
    };

    return (
        <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <button
                        onClick={handleTriggerLeadGenMeeting}
                        disabled={isLeadGenLoading}
                        className={`px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 ${
                            isLeadGenLoading
                                ? 'opacity-50 cursor-not-allowed'
                                : ''
                        }`}
                    >
                        {isLeadGenLoading
                            ? 'Sending...'
                            : 'Send Introduction Email'}
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="max-w-md p-4 bg-white shadow-lg rounded-lg border border-gray-200"
                        sideOffset={5}
                    >
                        <p className="text-sm text-gray-700">
                            An email will be sent out to introduce you to the
                            client. This helps initiate the conversation and
                            increases your chances of securing the project.
                        </p>
                        <Tooltip.Arrow className="fill-white" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
