const skillToSalary = (skillLevel: string): number => {
    if (skillLevel === 'junior') return 50;
    if (skillLevel === 'intermediate') return 100;
    if (skillLevel === 'senior') return 200;
    return 0;
};

const priorityRank = (priority: string): number => {
    if (priority === 'High') return 1;
    if (priority === 'Medium') return 2;
    if (priority === 'Low') return 3;
    return 0;
};

export const filterMilestonesByBudget = (
    milestones: any[],
    maxBudget: number,
    skillLevel: string
) => {
    const skillRate = skillToSalary(skillLevel);
    let accumulatedBudget = 0;

    const calculateCost = (hours: number): number => hours * skillRate;

    const sortedMilestones = milestones.map(milestone => ({
        ...milestone,
        sortedStories: [...milestone.stories].sort(
            (a, b) => priorityRank(a.priority) - priorityRank(b.priority)
        )
    }));

    const selectedStories = sortedMilestones.map(milestone => {
        const selectedStories = [];
        for (const story of milestone.sortedStories) {
            const originalStoryCost = calculateCost(story.hours);
            if (accumulatedBudget + originalStoryCost <= maxBudget) {
                accumulatedBudget += originalStoryCost;
                selectedStories.push({ ...story });
            } else {
                const trimmedStory = trimSubtasks(
                    story,
                    maxBudget - accumulatedBudget,
                    skillRate
                );
                if (trimmedStory) {
                    const trimmedStoryCost = calculateCost(trimmedStory.hours);
                    accumulatedBudget += trimmedStoryCost;
                    selectedStories.push(trimmedStory);
                }
            }
        }
        return selectedStories;
    });

    const rebuiltMilestones = milestones
        .map((milestone, milestoneIndex) => {
            const filteredStories = milestone.stories
                .filter(story =>
                    selectedStories[milestoneIndex].some(
                        selectedStory => selectedStory.id === story.id
                    )
                )
                .map(story => {
                    const selectedStory = selectedStories[milestoneIndex].find(
                        s => s.id === story.id
                    );
                    return selectedStory || story;
                });

            if (filteredStories.length > 0) {
                const updatedMilestone = {
                    ...milestone,
                    stories: filteredStories,
                    hours: filteredStories.reduce(
                        (total, story) => total + story.hours,
                        0
                    )
                };
                return updatedMilestone;
            }
            return null;
        })
        .filter(milestone => milestone !== null);

    return rebuiltMilestones;
};

const trimSubtasks = (
    story: any,
    remainingBudget: number,
    skillRate: number
): any | null => {
    const calculateCost = (hours: number): number => hours * skillRate;
    const trimmedStory = { ...story, subTasks: [], hours: 0 };

    for (const subtask of story.subTasks) {
        const subtaskCost = calculateCost(subtask.hours);

        if (remainingBudget >= subtaskCost) {
            remainingBudget -= subtaskCost;
            trimmedStory.hours += subtask.hours;
            trimmedStory.subTasks.push(subtask);
        }
    }

    return trimmedStory.hours > 0 ? trimmedStory : null;
};
