import * as seline from '@seline-analytics/web';

const eventToTrack = {
    matchWithDevEnabled: {
        google: 'conversion_event_purchase',
        reddit: 'SignUp',
        twitter: 'tw-on93x-oqz0y',
        seline: 'match_with_dev_enabled'
    },
    meetingScheduledWithPreDev: {
        google: 'conversion_event_book_appointment',
        reddit: 'AddToCart',
        twitter: 'tw-on93x-oqz18',
        seline: 'meeting_scheduled_with_pre_dev'
    },
    meetingScheduledWithAgency: {
        google: 'conversion_event_begin_checkout',
        reddit: 'Purchase',
        twitter: 'tw-on93x-oqz1f',
        seline: 'meeting_scheduled_with_agency'
    }
};

const trackEvent = async (e, options) => {
    //Google Tracking
    window.gtag('event', eventToTrack[e]?.google, {
        event_callback: async e => {},
        event_timeout: 2000
    });
    //Reddit Tracking
    try {
        window.rdt('track', eventToTrack[e]?.reddit);
    } catch (e) {
        console.log('error tracking reddit', e);
    }
    //Twitter Tracking
    try {
        window.twq('event', eventToTrack[e]?.twitter, {
            email_address: options?.userEmail,
            conversion_id: options?.conversionId
        });
    } catch (e) {
        console.log('error tracking twitter', e);
    }
    //Seline Tracking
    try {
        seline.track(eventToTrack[e]?.seline, {
            userId: options?.userId,
            email: options?.userEmail,
            conversionId: options?.conversionId,
            projectId: options?.projectId
        });
    } catch (e) {
        console.log('error tracking seline', e);
    }
};

export default trackEvent;
