import * as Slider from '@radix-ui/react-slider';
import { useMemo } from 'react';
import { toHumanPrice } from '../Docs/EditorHeader/PricingSection';
import { FilterByBudget } from './filterByBudget';

export const BudgetSlider = ({
    disabled,
    showFilter,
    budget,
    setBudget,
    estimateData,
    textColor = 'text-gray-300'
}) => {
    const formatBudget = value => {
        return value >= 250000 ? '$250,000+' : `${toHumanPrice(value * 100)}`;
    };

    return (
        <div className="w-full bg-gradient-to-br from-gray-900 to-black rounded-lg shadow-lg border-2 border-gray-700 py-4 px-4">
            <div className={`flex justify-between items-center mb-2`}>
                <span
                    className={`block text-left text-sm font-medium ${textColor}`}
                >
                    My Budget: {formatBudget(budget)}
                </span>
                {estimateData && (
                    <span className={`text-sm font-medium ${textColor}`}>
                        Est. Price: {toHumanPrice(estimateData * 100)}
                    </span>
                )}
            </div>
            <Slider.Root
                className="relative flex items-center select-none touch-none w-full h-8"
                value={[budget]}
                max={250000}
                min={5000}
                step={1000}
                onValueChange={value => setBudget(value[0])}
                disabled={disabled}
            >
                <Slider.Track className="relative bg-gray-500 grow rounded-full h-[6px] overflow-hidden">
                    <div className="absolute inset-0 bg-stars-pattern opacity-30"></div>
                    <Slider.Range
                        className="absolute rounded-full h-full shadow-lg transition-colors duration-300"
                        style={{
                            background: `linear-gradient(
                            to right,
                            hsl(${((budget - 5000) / 245000) * 120}, 70%, 50%),
                            hsl(${
                                ((budget - 5000) / 245000) * 120 + 30
                            }, 70%, 50%)
                        )`
                        }}
                    />
                </Slider.Track>
                {estimateData && (
                    <div
                        className="absolute w-1 h-8 bg-gradient-to-b from-green-400 to-green-600 rounded-full shadow-md"
                        style={{
                            left: `${Math.min(
                                ((estimateData - 5000) / 245000) * 100,
                                100
                            )}%`,
                            transform: 'translateX(-50%)',
                            transition: 'left 0.3s ease-out'
                        }}
                    ></div>
                )}
                <Slider.Thumb
                    className="z-10 block w-6 h-6 bg-white border-2 border-black shadow-xl rounded-full hover:scale-110 focus:outline-none focus:ring-4 transition-transform duration-300"
                    aria-label="Budget"
                />
            </Slider.Root>
            {showFilter && <FilterByBudget />}
        </div>
    );
};
