import { useDispatch } from 'react-redux';
import { setShowRequestModal } from '../../../redux/reducers/generic/project';

export function ScheduleConsultation() {
    const dispatch = useDispatch();

    return (
        <button
            onClick={() => dispatch(setShowRequestModal(true))}
            className="bg-gradient-to-r from-blue-500 to-indigo-500 flex text-xs rounded-lg items-center flex-row gap-x-2 text-white font-bold py-2 px-3 rounded border border-transparent hover:from-blue-600 hover:to-indigo-600 hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out"
        >
            Dev Consultation
        </button>
    );
}
