import { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSow,
    selectSkillLevel,
    selectPrices,
    setPrice
} from '../../../redux/reducers/generic/sow';
import {
    parseTotalHours,
    getPrice,
    toHumanPrice
} from '../Docs/EditorHeader/PricingSection';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import * as Tooltip from '@radix-ui/react-tooltip';
import { ProposalButton } from './ProposalButton';
import { MatchWithDev } from './matchWithDev';
import { setProfileModalOpen } from '../../../redux/reducers/generic/profile';
import { useNavigate } from 'react-router-dom';

export const Header = ({
    isMinimized,
    setIsMinimized,
    matchWithDev,
    setMatchWithDev,
    matchLoading
}) => {
    let architectureLoading = useSelector(selectProjectArchitectureLoading);
    const dispatch = useDispatch();
    const prices = useSelector(selectPrices);
    const { isEnterprise, isSolo, isHomePage, isSubbed } =
        useSubscriptionPayment();

    const navigate = useNavigate();

    const toggleMinimize = () => {
        const newValue = !isMinimized;
        setIsMinimized(newValue);
        window.localStorage.setItem(
            'budgetMinimized',
            JSON.stringify(newValue)
        );
    };

    let sowContent = useSelector(selectSow);
    const skillLevel = useSelector(selectSkillLevel);

    const hours = sowContent ? parseTotalHours(sowContent?.milestones) : 0;
    const [price, setPriceLocal] = useState(
        getPrice(skillLevel, hours, prices)
    );

    useEffect(() => {
        const calcPrice = getPrice(skillLevel, hours, prices);
        setPriceLocal(calcPrice);
        dispatch(setPrice(calcPrice));
    }, [prices, skillLevel, hours]);

    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex justify-between items-center gap-x-4">
                <h2 className="text-xl font-bold text-gray-100 flex items-center gap-x-4">
                    {isMinimized ? (
                        <Tooltip.Provider delayDuration={100}>
                            <Tooltip.Root>
                                <Tooltip.Trigger asChild>
                                    <div
                                        className="flex items-center gap-x-2 cursor-pointer"
                                        onClick={() => {
                                            if (isMinimized) {
                                                toggleMinimize();
                                            }
                                        }}
                                    >
                                        <img
                                            src="/predev.png"
                                            alt="Budget"
                                            className="h-5 cursor-pointer"
                                        />
                                        <span className="text-md">Budget </span>
                                    </div>
                                </Tooltip.Trigger>
                                {/* <Tooltip.Portal>
                                <Tooltip.Content
                                    className="max-w-xs z-[100] p-2 bg-white text-black rounded border border-gray-200"
                                    sideOffset={20}
                                    side="left"
                                >
                                    <p className="text-md">Budget </p>
                                    <Tooltip.Arrow className="fill-white" />
                                </Tooltip.Content>
                            </Tooltip.Portal> */}
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    ) : (
                        <>
                            <img
                                onClick={() => {
                                    if (isMinimized) {
                                        toggleMinimize();
                                    }
                                }}
                                src="/predev.png"
                                alt="Budget"
                                className="h-5 cursor-pointer"
                            />
                            <span className="text-md">Budget </span>
                        </>
                    )}
                </h2>
                <ProposalButton />
                <div className="flex items-center">
                    <button
                        onClick={toggleMinimize}
                        className="text-gray-100 hover:text-gray-300 transition-colors duration-200"
                    >
                        {!isMinimized ? (
                            <ChevronDown size={20} />
                        ) : (
                            <ChevronUp size={20} />
                        )}
                    </button>
                </div>
            </div>
            {isMinimized && (isSolo || isHomePage) && !matchWithDev && (
                <MatchWithDev
                    matchWithDev={matchWithDev}
                    setMatchWithDev={setMatchWithDev}
                    matchLoading={matchLoading}
                    smallVersion={true}
                />
            )}
            {(isSolo || isHomePage) && !isSubbed && (
                <button
                    onClick={() => {
                        if (isSolo) dispatch(setProfileModalOpen(true));
                        else if (isHomePage)
                            navigate('/auth?redirectToPath=/projects');
                    }}
                    className="w-fit inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 rounded-md shadow-sm hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-200 transform hover:scale-105"
                >
                    <span className="mr-2">🎁</span>
                    Get Solo Plus Free
                    <span className="ml-2">→</span>
                </button>
            )}
        </div>
    );
};
