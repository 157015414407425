import { UserEmail } from './userEmail';
import { ProjectName } from './projectName';
import { ForkProject } from './fork';

export const TableItem = ({ project, setCurrentProject, currentProject }) => {
    const onClickProject = () => {
        window.open(`${window.location.href}/${project._id}`, '_blank');
    };

    const showShareLink = currentProject?._id === project._id;
    return (
        <tr
            className="cursor-pointer hover:bg-gray-50"
            key={project._id}
            onMouseEnter={() => setCurrentProject(project)}
            onMouseLeave={() => setCurrentProject(null)}
        >
            <td
                className="px-2 py-3 text-sm font-medium text-gray-900 max-w-[400px]"
                onClick={onClickProject}
            >
                <ProjectName project={project} />
            </td>
            <td className="px-2 py-3 text-sm font-medium text-gray-500">
                <UserEmail project={project} showShareLink={showShareLink} />
            </td>

            <td
                onClick={onClickProject}
                className="hidden whitespace-nowrap px-2 py-3 text-sm text-gray-500 md:table-cell"
            >
                {project?.budget && (
                    <>${parseInt(project?.budget)?.toLocaleString()}</>
                )}
            </td>
            <td
                onClick={onClickProject}
                className="hidden whitespace-nowrap px-2 py-3 text-center text-xs text-gray-500 md:table-cell"
            >
                {formatDate(project.updated)}
            </td>
            <td className="hidden whitespace-nowrap px-2 py-3 text-center text-sm text-gray-500 md:table-cell">
                <ForkProject project={project} />
            </td>
        </tr>
    );
};

const formatDate = isoDate => {
    const date = new Date(isoDate);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit'
        });
    } else {
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        });
    }
};
