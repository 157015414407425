import { toast } from 'sonner';
import { client } from '../../../api/client';
import { GET_CLIENT_JIRA_PROJECTS } from '../../../api/graphQL/enterprise/query';

export const getClientJiraProjects = () => async (dispatch, getState) => {
    try {
        let { data } = await client.mutate({
            mutation: GET_CLIENT_JIRA_PROJECTS,
            variables: {}
        });

        console.log({ data });

        return data.getClientJiraProjects;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
        return [];
    }
};
