import { useEffect, useCallback, useState, useMemo } from 'react';
import './index.css';
import {
    ReactFlow,
    MarkerType,
    ReactFlowProvider,
    useReactFlow,
    Node,
    Edge,
    Controls,
    NodeMouseHandler,
    useNodesState,
    useEdgesState,
    MiniMap,
    ConnectionLineType
} from '@xyflow/react';
import Drawer from './Drawer';
import { useSelector } from 'react-redux';
import { useProject } from '../../../../context-providers/Project';
import { useDispatch } from 'react-redux';
import { getGraphsByVersion } from '../../../../redux/actions/solo/getGraphsByVersion';
import TurboNode from './TurboNode';
import TurboEdge from './TurboEdge';
import {
    selectFreeNodeLabel,
    selectGraphVersion,
    selectProjectArchitectureLoading
} from '../../../../redux/reducers/generic/projectArchitecture';
// This is used to display a leva (https://github.com/pmndrs/leva) control panel for the example
import { useControls, button, Leva } from 'leva';
import useAutoLayout, { type LayoutOptions } from './useAutoLayout';
import { setShowNodeDetailModal } from '../../../../redux/reducers/generic/projectArchitecture';
import { getId } from './utils';

import '@xyflow/react/dist/style.css';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { toast } from 'sonner';
import { capitalizeGraphs } from '../../../../constants';
import {
    selectDrawerOpen,
    selectSelectedNode,
    setDrawerOpen,
    setSelectedNodeDrawer
} from '../../../../redux/reducers/generic/project';
import { toggleBillingModalOpen } from '../../../../redux/reducers/generic/billing';

const proOptions = {
    account: 'paid-pro',
    hideAttribution: true
};

const defaultEdgeOptions = {
    type: 'turbo',
    markerEnd: { type: MarkerType.ArrowClosed },
    pathOptions: { offset: 5 }
};
const nodeTypes = {
    turbo: TurboNode
};

const edgeTypes = {
    turbo: TurboEdge
};

function ReactFlowAutoLayout({ nodeData, edgeData }) {
    const dispatch = useDispatch();
    const graphVersion = useSelector(selectGraphVersion);
    const freeNodeLabel = useSelector(selectFreeNodeLabel);
    let architectureLoading = useSelector(selectProjectArchitectureLoading);

    const { fitView } = useReactFlow();
    let {
        setSearchParams,
        allSearchParams,
        project,
        graphType,
        pageType,
        projectArchitecture
    } = useProject();

    const graph = useMemo(
        () => projectArchitecture.graphs.find(g => g.side === graphType),
        [projectArchitecture.graphs, graphType]
    );

    let { isSubbed, tier, isSolo, isHomePage, isEnterprise, isShare } =
        useSubscriptionPayment();

    isSubbed = isSubbed && !isHomePage;

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [isLoading, setIsLoading] = useState(false);

    const open = useSelector(selectDrawerOpen);
    const setOpen = (open: boolean) => {
        dispatch<any>(setDrawerOpen(open));
    };
    const setSelectedNode = (node: Node) => {
        dispatch<any>(setSelectedNodeDrawer(node));
    };
    const selectedNode = useSelector(selectSelectedNode);
    // const [firstVersionChange, setFirstVersionChange] = useState(false);
    // const [firstGraphChange, setFirstGraphChange] = useState(false);

    const [layoutOptions, setLayoutOptions] = useControls(() => {
        let firstVersionChange = false;
        let firstGraphChange = false;

        const baseOptions = {
            about: {
                value: 'Easily toggle between graph versions.',
                editable: false
            }
        };

        if (pageType === 'code') {
            return {
                ...baseOptions,
                ...(architectureLoading
                    ? {}
                    : {
                          version: {
                              value: graphVersion || 0,
                              options: project?.graphVersions,
                              onChange: value => {
                                  if (!firstVersionChange) {
                                      firstVersionChange = true;

                                      setLayoutOptions({
                                          version: graphVersion
                                      });
                                      return;
                                  }
                                  if (!isLoading && project?.graphVersions) {
                                      dispatch<any>(
                                          getGraphsByVersion(
                                              project._id.toString(),
                                              value
                                          )
                                      );
                                  }
                              }
                          }
                      })
            };
        }

        const validGraphTypes = Array.from(
            new Set(projectArchitecture.graphs.map(g => g.side as string))
        );
        const graphOptions = validGraphTypes.map(g =>
            capitalizeGraphs(g as string)
        );

        const graphControl = {
            graph: {
                value: capitalizeGraphs(graphType),
                options: graphOptions,
                onChange: value => {
                    const searchParam = value.toLowerCase();
                    if (validGraphTypes.includes(searchParam)) {
                        setSearchParams({
                            ...allSearchParams,
                            graphType: searchParam
                        });
                    }
                }
            }
        };

        const versionControl = architectureLoading
            ? {}
            : {
                  version: {
                      value: graphVersion || 0,
                      options: project?.graphVersions,
                      onChange: value => {
                          if (!firstVersionChange) {
                              firstVersionChange = true;
                              setLayoutOptions({ version: graphVersion });
                              return;
                          }
                          if (
                              !isLoading &&
                              project?.graphVersions &&
                              value != graphVersion
                          ) {
                              dispatch<any>(
                                  getGraphsByVersion(
                                      project._id.toString(),
                                      value
                                  )
                              );
                          }
                      }
                  }
              };

        return {
            ...baseOptions,
            ...graphControl,
            ...versionControl
        };
    }, [project?.graphVersions, pageType, architectureLoading]);

    useEffect(() => {
        if (nodeData && edgeData) {
            setIsLoading(true);

            const newNodes = nodeData.map(node => {
                if (graphType === 'frontend' && node.id === 'core') {
                    return {
                        ...node,
                        data: { ...node.data, label: 'Core' },
                        style: { ...node.style, opacity: 0 }
                    };
                } else if (graphType === 'backend' && node.id === 'core') {
                    return {
                        ...node,
                        data: { ...node.data, label: 'Core Services' },
                        style: { ...node.style, opacity: 0 }
                    };
                } else {
                    return { ...node, style: { ...node.style, opacity: 0 } };
                }
            });
            const newEdges = edgeData.map(edge => ({
                ...edge,
                style: { ...edge.style, opacity: 0 }
            }));

            setNodes(newNodes);
            setEdges(newEdges);

            // setTimeout(() => {
            setLayoutOptions({
                ...(pageType !== 'code' && { graph: graphType }),
                ...(!architectureLoading && { version: graphVersion })
            });
            // }, 500);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }, [graphType, graphVersion, layoutOptions, pageType, architectureLoading]);

    // this hook handles the computation of the layout once the elements or the direction changes
    useAutoLayout(layoutOptions);
    // this helper function adds a new node and connects it to the source node
    useCallback(
        (parentNodeId: string) => {
            // create an incremental ID based on the number of elements already in the graph
            const childNodeId = getId();

            const childNode: Node = {
                id: childNodeId,
                data: { label: `Node ${nodes?.length + 1}` },
                position: { x: 0, y: 0 }, // no need to pass a position as it is computed by the layout hook
                style: { opacity: 0 }
            };

            const connectingEdge: Edge = {
                id: `${parentNodeId}->${childNodeId}`,
                type: 'custom',
                source: parentNodeId,
                target: childNodeId,
                style: { opacity: 0 }
            };

            setNodes(nodes => nodes.concat([childNode]));
            setEdges(edges => edges.concat([connectingEdge]));
        },
        [setNodes, setEdges, nodes?.length]
    );

    // // this function is called when a node in the graph is clicked
    const onNodeClick: NodeMouseHandler = useCallback(
        (_, node) => {
            let premiumName = 'Solo Premium';
            if (isEnterprise) {
                premiumName = 'Enterprise Premium';
            }
            if (isHomePage || isShare) {
                premiumName = 'Solo / Enterprise Premium';
            }
            if (graphType == 'frontend' && pageType === 'code') {
                if (
                    (isSubbed && tier !== 'SoloPlus') ||
                    (freeNodeLabel?.toLowerCase() ===
                        (node?.data?.label as string)?.toLowerCase() &&
                        !isShare)
                ) {
                    if (node.data.label === 'Core') {
                        if (!isSubbed) {
                            toast.error(
                                `You must be subscribed to ${premiumName} to view the code for this page.`
                            );
                        }
                        return;
                    }
                    const nd = {
                        label: node.data.label,
                        description: node.data.description,
                        id: node.id,
                        _id: node.data._id
                    };

                    dispatch<any>(setShowNodeDetailModal(nd));
                } else {
                    toast.error(
                        `You must be subscribed to ${premiumName} to view the code for this page.`
                    );
                    if (isSolo || isEnterprise) {
                        dispatch<any>(toggleBillingModalOpen(true));
                    }
                }
            }
            if (pageType == 'chat' || pageType == 'graph') {
                setSelectedNode(node);
                setOpen(true);
            }
        },
        [graphType]
    );

    useEffect(() => {
        fitView();
    }, [nodes, fitView]);

    return (
        <>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={onNodeClick}
                colorMode={'dark'}
                minZoom={0.01}
                nodesDraggable={false}
                defaultEdgeOptions={defaultEdgeOptions}
                connectionLineType={ConnectionLineType.SmoothStep}
                proOptions={proOptions}
                zoomOnDoubleClick={false}
            >
                <Controls />

                <div className="z-10 absolute top-3 right-3">
                    <Leva fill />
                </div>

                <svg>
                    <defs>
                        <linearGradient id="edge-gradient">
                            <stop offset="0%" stopColor="#ae53ba" />
                            <stop offset="100%" stopColor="#2a8af6" />
                        </linearGradient>

                        <marker
                            id="edge-circle"
                            viewBox="-5 -5 10 10"
                            refX="0"
                            refY="0"
                            markerUnits="strokeWidth"
                            markerWidth="10"
                            markerHeight="10"
                            orient="auto"
                        >
                            <circle
                                stroke="#2a8af6"
                                strokeOpacity="0.75"
                                r="2"
                                cx="0"
                                cy="0"
                            />
                        </marker>
                    </defs>
                </svg>
            </ReactFlow>
            <Drawer
                open={open}
                setLayoutOptions={setLayoutOptions}
                setOpen={setOpen}
                selectedNode={selectedNode}
                setSelectedNode={setSelectedNode}
            />
        </>
    );
}

const ReactFlowWrapper = ({ nodes, edges }) => {
    return (
        <ReactFlowProvider>
            <ReactFlowAutoLayout nodeData={nodes} edgeData={edges} />
        </ReactFlowProvider>
    );
};

export default ReactFlowWrapper;
