'use client';
import { useState, useEffect, useRef } from 'react';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import {
    XMarkIcon,
    PlusIcon,
    ChevronDownIcon
} from '@heroicons/react/24/outline';
import TextareaAutosize from 'react-textarea-autosize';
import { useProject } from '../../../../context-providers/Project';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { selectSow } from '../../../../redux/reducers/generic/sow';
import { deleteUserStory } from '../../../../redux/actions/solo/deleteUserStory';
import { editUserStory } from '../../../../redux/actions/solo/editUserStory';
import { addUserStory } from '../../../../redux/actions/solo/addUserStory';
import { editNode } from '../../../../redux/actions/solo/editNode';
import { CreateNode } from '../CreateNode';
import { cn } from '../../../Components/magicui/lib/utils';
import { buttonVariants } from '../../../Components/magicui/ui/button';
import { ChevronLeft } from 'lucide-react';
import { deleteNode } from '../../../../redux/actions/solo/deleteNode';
import { SubtasksDialog } from './SubTaskDialog';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { capitalizeGraphs } from '../../../../constants';
import levenshtein from 'fast-levenshtein';

export default function Drawer({
    open,
    setOpen,
    selectedNode,
    setSelectedNode,
    setLayoutOptions
}) {
    const {
        project,
        graphType,
        searchParams,
        setSearchParams,
        allSearchParams,
        projectId
    } = useProject();
    const dispatch: any = useDispatch();
    let sow = useSelector(selectSow);
    let stories = [];
    if (graphType === 'frontend') {
        try {
            const userFlowNodes = project?.graphs?.find(
                graph => graph.side === 'user flow'
            )?.nodes;

            const reactPageNodes = userFlowNodes?.filter(
                node =>
                    node.reactPage &&
                    levenshtein.get(
                        node.reactPage.replace(/\s+/g, '').toLowerCase(),
                        selectedNode?.data.label
                            .replace(/\s+/g, '')
                            .toLowerCase()
                    ) === 0
            );

            stories = sow?.milestones
                ?.map(milestone => milestone.stories)
                .flat()
                .filter(
                    story =>
                        reactPageNodes?.some(node => node._id === story.nodeId)
                );
        } catch (error) {
            console.error('Error processing user flow nodes:', error);
            stories = [];
        }
    } else if (graphType === 'backend') {
        stories = sow?.milestones
            ?.map(milestone => milestone.stories)
            .flat()
            .filter(
                story =>
                    story.architectureNodeIdCitations?.includes(
                        selectedNode?.id
                    )
            );
    } else {
        stories = sow?.milestones
            ?.map(milestone => milestone.stories)
            .flat()
            .filter(story => story.nodeId === selectedNode?.data._id);
    }
    const reactPage = project?.graphs
        ?.find(graph => graph.side === 'user flow')
        ?.nodes.find(node => node._id === selectedNode?.data._id)?.reactPage;

    const [description, setDescription] = useState(
        selectedNode?.data.description || ''
    );
    const [assignee, setAssignee] = useState('Arjun Jain, Adam Elkassas');
    const [status, setStatus] = useState('In progress');
    const [createdTime, setCreatedTime] = useState(null);

    // const [lastEditedTime, setLastEditedTime] = useState(
    //     'August 26, 2024 3:56 PM'
    // );
    const [label, setLabel] = useState(selectedNode?.data.label || '');
    const [showDropdown, setShowDropdown] = useState(false);
    const [assignees, setAssignees] = useState(['Arjun Jain', 'Adam Elkassas']);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const availableAssignees = ['John Doe', 'Jane Smith', 'Alice Johnson'];
    const [currentStoryId, setCurrentStoryId] = useState(null);
    const [userStories, setUserStories] = useState([]);
    const [showAddStoryDialog, setShowAddStoryDialog] = useState(false);
    const [newStoryDescription, setNewStoryDescription] = useState('');
    const [newStoryAcceptanceCriteria, setNewStoryAcceptanceCriteria] =
        useState('');
    const [priority, setPriority] = useState('medium');
    const [isLoading, setIsLoading] = useState(false);
    const [showSubtasksDialog, setShowSubtasksDialog] = useState(false);
    const [subtasks, setSubtasks] = useState([]);
    const subtasksListRef = useRef(null);
    const [originalDescription, setOriginalDescription] = useState('');
    const [originalLabel, setOriginalLabel] = useState('');
    const [createNodeOpen, setCreateNodeOpen] = useState(false);
    const [nodeHistory, setNodeHistory] = useState([]);
    const [roles, setRoles] = useState([]);
    const [storyId, setStoryId] = useState(null);

    const generateRolesList = async () => {
        if (!sow || !sow.milestones) return [];

        try {
            const roles = sow?.milestones
                ?.flatMap(epic => epic?.stories)
                ?.flatMap(story => story?.subTasks)
                ?.flatMap(subTask => subTask?.roles || [])
                ?.reduce((acc, role) => {
                    if (!acc.some(item => item?.name === role?.name)) {
                        acc.push(role);
                    }
                    return acc;
                }, [])
                ?.filter(role => role?.name && role?.shortHand);

            setRoles(roles);
        } catch (error) {
            return [];
        }
    };

    useEffect(() => {
        generateRolesList();
    }, [sow]);

    const removeStory = async storyId => {
        // const updatedStories = userStories.filter((_, i) => i !== index);
        // setUserStories(updatedStories);
        await dispatch(deleteUserStory(storyId, project._id.toString()));
        toast('Successfully deleted user story!');
    };

    useEffect(() => {
        setOriginalDescription(selectedNode?.data.description || '');
        setOriginalLabel(selectedNode?.data.label || '');
        if (selectedNode?.data.created)
            setCreatedTime(
                new Date(selectedNode?.data.created).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                })
            );
    }, [selectedNode]);

    const addNewStory = async storyId => {
        setIsLoading(true);
        if (storyId) {
            await dispatch(
                editUserStory(
                    storyId,
                    project._id.toString(),
                    newStoryDescription,
                    priority,
                    newStoryAcceptanceCriteria
                )
            );
            setIsLoading(false);
            toast('Successfully edited user story!');
        } else {
            dispatch(
                addUserStory(
                    selectedNode.data._id,
                    project._id.toString(),
                    newStoryDescription,
                    priority,
                    newStoryAcceptanceCriteria
                )
            );

            setTimeout(() => {
                setIsLoading(false);
                setShowAddStoryDialog(false);
                setNewStoryDescription('');
                setNewStoryAcceptanceCriteria('');
                toast('Successfully added user story and generated subtasks!');
            }, 2000);
        }
    };

    useEffect(() => {
        setDescription(selectedNode?.data.description || '');
        setAssignees(['Arjun Jain', 'Adam Elkassas']);
        setStatus('In progress');
        setLabel(selectedNode?.data.label || '');
    }, [selectedNode]);

    const addAssignee = newAssignee => {
        if (!assignees.includes(newAssignee)) {
            setAssignees([...assignees, newAssignee]);
        }
        setShowDropdown(false);
    };

    const { isSubbed, isShare, isHomePage, isSolo, isLeadGen } =
        useSubscriptionPayment();
    const openSubtasksDialog = story => {
        if (isSubbed) {
            setSubtasks(story.subTasks || []);
            setStoryId(story._id);
            setShowSubtasksDialog(true);
        } else {
            toast('You need to be a subscriber to view the subtasks!');
        }
    };

    const saveNode = async () => {
        if (originalLabel !== label || originalDescription !== description) {
            const graphId = project.graphs
                .find(g => g.side === graphType)
                ._id.toString();

            dispatch(
                editNode(
                    selectedNode.data._id,
                    graphId,
                    project._id.toString(),
                    label,
                    description
                )
            );
        }
    };

    const onClickPage = page => {
        const frontendGraph = project.graphs.find(
            graph => graph.side === 'frontend'
        );
        const node = frontendGraph.nodes.reduce((closestNode, currentNode) => {
            const currentDistance = levenshtein.get(
                currentNode.label.replace(/\s+/g, '').toLowerCase(),
                page.replace(/\s+/g, '').toLowerCase()
            );
            const closestDistance = closestNode
                ? levenshtein.get(
                      closestNode.label.replace(/\s+/g, '').toLowerCase(),
                      page.replace(/\s+/g, '').toLowerCase()
                  )
                : Infinity;
            return currentDistance < closestDistance
                ? currentNode
                : closestNode;
        }, null);

        if (node) {
            // setOpen(false);
            const nd = {
                data: {
                    _id: node._id,
                    label: node.label,
                    description: node.description
                },
                id: node._id
            };
            setNodeHistory([...nodeHistory, selectedNode]);
            setSelectedNode(nd);
            // setTimeout(() => {
            //     setOpen(true);
            // }, 1000);

            setSearchParams({
                ...allSearchParams,
                graphType: 'frontend'
            });
            setLayoutOptions({
                graph: 'Frontend'
            });
        }
    };

    useEffect(() => {
        if (subtasksListRef.current) {
            const isScrollable =
                subtasksListRef.current.scrollHeight >
                subtasksListRef.current.clientHeight;
            subtasksListRef.current.classList.toggle(
                'scrollable',
                isScrollable
            );
        }
    }, [subtasks]);

    const onClickNode = id => {
        const node = project.graphs
            .find(graph => graph.side === 'backend')
            .nodes.find(node => node.id === id);
        if (node) {
            // setOpen(false);
            const nd = {
                data: {
                    _id: node._id,
                    label: node.label,
                    description: node.description
                },
                id: id
            };
            setNodeHistory([...nodeHistory, selectedNode]);
            setSelectedNode(nd);
            // setTimeout(() => {
            //     setOpen(true);
            // }, 1000);

            setSearchParams({
                ...allSearchParams,
                graphType: 'backend'
            });
            setLayoutOptions({
                graph: 'Backend'
            });
        }
    };

    const onDeleteNode = async () => {
        if (window.confirm('Are you sure you want to delete this node?')) {
            setOpen(false);
            const graphId = project.graphs
                .find(g => g.side === graphType)
                ._id.toString();
            console.log(
                'deleting node: ' + JSON.stringify(selectedNode, null, 2)
            );
            await dispatch(
                deleteNode(
                    selectedNode.data._id,
                    graphId,
                    project._id.toString()
                )
            );
            setSelectedNode(null);
        }
    };

    const goBack = () => {
        console.log({ nodeHistory });
        if (nodeHistory.length > 0) {
            const lastNode = nodeHistory[nodeHistory.length - 1];
            setNodeHistory(nodeHistory.slice(0, -1));
            setSelectedNode(lastNode);
            setSearchParams({
                ...allSearchParams,
                graphType: lastNode.data.side
            });
            setLayoutOptions({
                graph: capitalizeGraphs(lastNode.data?.side)
            });
            setOpen(true);
        }
    };
    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
                saveNode();
                setNodeHistory([]); // Reset node history
            }}
            className="relative z-100"
        >
            <div className="fixed inset-0 bg-[#1C1E22] bg-opacity-50" />
            <CreateNode
                selectedNode={selectedNode}
                open={createNodeOpen}
                setOpen={setCreateNodeOpen}
            />
            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <DialogPanel
                            transition
                            className="pointer-events-auto w-screen max-w-2xl transform transition duration-250 ease-in-out data-[closed]:translate-x-full sm:duration-350"
                        >
                            <div className="flex h-full flex-col overflow-y-scroll bg-black py-8 px-6 shadow-xl">
                                <div className="px-4 mt-10 sm:px-6">
                                    {nodeHistory.length > 0 && (
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                goBack();
                                            }}
                                            className={cn(
                                                buttonVariants({
                                                    variant: 'ghost'
                                                }),
                                                'mt-4 mb-4 text-white'
                                            )}
                                        >
                                            <>
                                                <ChevronLeft className="mr-2 h-4 w-4" />
                                                Back
                                            </>
                                        </button>
                                    )}
                                    <div className="flex items-start justify-between">
                                        <input
                                            type="text"
                                            value={label}
                                            onChange={e =>
                                                setLabel(e.target.value)
                                            }
                                            className="text-3xl font-semibold leading-6 text-white bg-transparent border-none p-2 w-full focus:bg-gray-800 focus:outline-none focus:ring-0 focus:border-transparent hover:bg-gray-800 transition-colors duration-200 rounded-md cursor-pointer"
                                        />
                                        <div className="flex h-7 items-center absolute top-4 left-4">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setOpen(false);
                                                    saveNode();
                                                    setNodeHistory([]); // Reset node history
                                                }}
                                                className="relative rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <span className="sr-only">
                                                    Close panel
                                                </span>
                                                <svg
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative mt-6 flex-1 px-4 sm:px-6 ml-4">
                                    {selectedNode && (
                                        <div className="space-y-4">
                                            <div>
                                                <h3 className="text-md font-medium leading-6 text-white">
                                                    Description
                                                </h3>
                                                <TextareaAutosize
                                                    value={description}
                                                    disabled={
                                                        isShare || isLeadGen
                                                    }
                                                    onChange={e =>
                                                        setDescription(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="mt-1 text-sm text-gray-400 bg-transparent border-none p-2 w-full focus:bg-gray-800 focus:outline-none focus:ring-0 focus:border-transparent hover:bg-gray-800 transition-colors duration-200 rounded-md cursor-pointer"
                                                />
                                            </div>

                                            {graphType === 'frontend' && (
                                                <div className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-600 text-white">
                                                    Frontend Page
                                                </div>
                                            )}
                                            {graphType === 'backend' && (
                                                <div className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-600 text-white">
                                                    Backend
                                                </div>
                                            )}
                                            {!isShare && !isLeadGen && (
                                                <>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-2 py-1 bg-gray-700 text-sm font-medium text-gray-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto"
                                                            onClick={() => {
                                                                //toast("New node added!");
                                                                setCreateNodeOpen(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            + Add New Node
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-2 py-1 bg-red-700 text-sm font-medium text-gray-300 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto"
                                                            onClick={
                                                                onDeleteNode
                                                            }
                                                        >
                                                            Delete Node
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                            {/* <div>
                        <h3 className="text-md font-medium leading-6 text-white">Assignee</h3>
                        <div className="flex items-center space-x-2 mt-1">
                          {assignees.map((assignee, index) => (
                            <span key={index} className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-800 text-white">
                              {assignee}
                              <button
                                type="button"
                                onClick={() => setAssignees(assignees.filter(a => a !== assignee))}
                                className="ml-2 text-gray-400 hover:text-gray-500 focus:outline-none"
                              >
                                <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                              </button>
                            </span>
                          ))}
                          <button
                            type="button"
                            onClick={() => setShowDropdown(!showDropdown)}
                            className="inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            +
                          </button>
                          {showDropdown && (
                            <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {availableAssignees.map((availableAssignee, index) => (
                                  <button
                                    key={index}
                                    onClick={() => addAssignee(availableAssignee)}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                    role="menuitem"
                                  >
                                    {availableAssignee}
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <h3 className="text-md font-medium leading-6 text-white">Status</h3>
                        <div className="relative mt-1">
                          <button
                            type="button"
                            onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                            className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                              status === 'Open' ? 'bg-green-500' : status === 'In Progress' ? 'bg-yellow-500' : 'bg-red-500'
                            }`}
                          >
                            {status}
                          </button>
                          {showStatusDropdown && (
                            <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {['Open', 'In Progress', 'Closed'].map((option, index) => (
                                  <button
                                    key                                    onClick={() => {
                                      setStatus(option);
                                      setShowStatusDropdown(false);
                                    }}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                    role="menuitem"
                                  >
                                    {option}
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div> */}

                                            <div>
                                                {reactPage && (
                                                    <li className="flex items-center justify-between py-3">
                                                        <div className="flex items-center">
                                                            <div className="">
                                                                <p className="mt-1 text-sm text-white">
                                                                    <strong>
                                                                        Frontend
                                                                        Page:
                                                                    </strong>
                                                                </p>
                                                                <div className="mt-1 flex flex-wrap">
                                                                    <span
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            onClickPage(
                                                                                reactPage
                                                                            );
                                                                        }}
                                                                        className="cursor-pointer mr-2 mb-2 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gradient-to-r from-purple-400 to-pink-500 text-white shadow-lg transition-transform duration-200 transform hover:scale-105"
                                                                    >
                                                                        {
                                                                            reactPage
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                                <h3 className="text-md font-medium leading-6 text-white">
                                                    User Stories
                                                </h3>
                                                <ul
                                                    role="list"
                                                    className="mt-2"
                                                >
                                                    {!isShare && !isLeadGen && (
                                                        <li className="flex items-center justify-between py-2 border-b-[1px] border-gray-500">
                                                            <button
                                                                type="button"
                                                                className="group -ml-1 flex items-center rounded-md p-1 px-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:bg-gray-800 transition-colors duration-200 rounded-md cursor-pointer"
                                                                onClick={() =>
                                                                    setShowAddStoryDialog(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <span className="flex h-4 w-4 items-center justify-center rounded-full border-1 border-dashed border-gray-300 text-gray-400">
                                                                    <PlusIcon
                                                                        aria-hidden="true"
                                                                        className="h-4 w-4"
                                                                    />
                                                                </span>
                                                                <span className="ml-2 text-sm font-medium text-gray-400">
                                                                    Add Story
                                                                </span>
                                                            </button>
                                                        </li>
                                                    )}
                                                    {stories?.map(
                                                        (story, index) => (
                                                            <li
                                                                key={index}
                                                                className="rounded-md cursor-pointer hover:bg-gray-800 flex items-center justify-between py-3 border-b-[1px] border-gray-500"
                                                            >
                                                                <div
                                                                    className="flex items-center"
                                                                    onClick={() => {
                                                                        setCurrentStoryId(
                                                                            story._id
                                                                        );
                                                                        openSubtasksDialog(
                                                                            story
                                                                        );
                                                                    }}
                                                                >
                                                                    <div className="ml-4">
                                                                        <h4 className="text-sm font-medium text-white mb-1">
                                                                            {
                                                                                story.title
                                                                            }
                                                                        </h4>
                                                                        <p className="mt-1 text-sm text-gray-400 bg-gray-700 p-2 rounded">
                                                                            <i>
                                                                                {
                                                                                    story.description
                                                                                }
                                                                            </i>
                                                                        </p>
                                                                        <p className="mt-1 text-xs text-gray-400">
                                                                            <strong>
                                                                                Acceptance
                                                                                Criteria:
                                                                            </strong>
                                                                        </p>
                                                                        <p className="mt-1 text-xs text-gray-400">
                                                                            {
                                                                                story.acceptance_criteria
                                                                            }
                                                                        </p>
                                                                        <p className="mt-1 text-xs text-gray-400 flex items-center mb-2">
                                                                            <strong>
                                                                                Priority:
                                                                            </strong>
                                                                            <span
                                                                                className={`ml-2 mt-1 text-xs font-medium px-2.5 py-0.5 rounded ${
                                                                                    story.priority ===
                                                                                    'high'
                                                                                        ? 'bg-red-600 text-white'
                                                                                        : story.priority ===
                                                                                            'medium'
                                                                                          ? 'bg-yellow-500 text-white'
                                                                                          : 'bg-green-600 text-white'
                                                                                } flex items-center`}
                                                                            >
                                                                                {story.priority
                                                                                    .charAt(
                                                                                        0
                                                                                    )
                                                                                    .toUpperCase() +
                                                                                    story.priority.slice(
                                                                                        1
                                                                                    )}
                                                                            </span>
                                                                        </p>
                                                                        <p className="mt-1 text-xs text-gray-400 mb-2">
                                                                            <strong>
                                                                                Architecture
                                                                                Nodes:
                                                                            </strong>
                                                                        </p>
                                                                        <div className="mt-1 flex flex-wrap">
                                                                            {story.architectureNodeIdCitations?.map(
                                                                                (
                                                                                    citation,
                                                                                    index
                                                                                ) => (
                                                                                    <span
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        onClick={e => {
                                                                                            e.stopPropagation();
                                                                                            onClickNode(
                                                                                                citation
                                                                                            );
                                                                                        }}
                                                                                        className="mr-1 mb-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-600 text-white hover:bg-gray-700 transition-colors duration-200"
                                                                                    >
                                                                                        {
                                                                                            citation
                                                                                        }
                                                                                    </span>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <button
                                                                        type="button"
                                                                        className="ml-6 rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                        onClick={() => {
                                                                            if (
                                                                                !isShare &&
                                                                                !isLeadGen
                                                                            ) {
                                                                                setCurrentStoryId(
                                                                                    story._id
                                                                                );
                                                                                setNewStoryDescription(
                                                                                    story.description
                                                                                );
                                                                                setNewStoryAcceptanceCriteria(
                                                                                    story.acceptance_criteria
                                                                                );
                                                                                setPriority(
                                                                                    story.priority
                                                                                );
                                                                                setShowAddStoryDialog(
                                                                                    true
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {!isShare &&
                                                                            !isLeadGen &&
                                                                            'Edit'}
                                                                        <span className="sr-only">
                                                                            {' '}
                                                                            {
                                                                                story.title
                                                                            }
                                                                        </span>
                                                                    </button>
                                                                    {!isShare &&
                                                                        !isLeadGen && (
                                                                            <button
                                                                                type="button"
                                                                                className="ml-6 mr-4 rounded-md text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            `Are you sure you want to remove the story: ${story.title}?`
                                                                                        )
                                                                                    ) {
                                                                                        removeStory(
                                                                                            story._id
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Remove
                                                                                <span className="sr-only">
                                                                                    {' '}
                                                                                    {
                                                                                        story.title
                                                                                    }
                                                                                </span>
                                                                            </button>
                                                                        )}
                                                                </div>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                                {showAddStoryDialog && (
                                                    <div className="fixed z-10 inset-0 overflow-y-auto">
                                                        <div className="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
                                                            <div
                                                                className="fixed inset-0 transition-opacity"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="absolute inset-0 bg-black opacity-75"></div>
                                                            </div>
                                                            <span
                                                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                                                aria-hidden="true"
                                                            >
                                                                &#8203;
                                                            </span>
                                                            <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                                                <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                                    <div className="sm:flex sm:items-start">
                                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                                                            {!isShare &&
                                                                                !isLeadGen && (
                                                                                    <div className="flex justify-between">
                                                                                        <h3 className="text-lg leading-6 font-medium text-white">
                                                                                            Add
                                                                                            User
                                                                                            Story
                                                                                        </h3>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="text-gray-400 hover:text-gray-500 focus:outline-none"
                                                                                            onClick={() => {
                                                                                                setShowAddStoryDialog(
                                                                                                    false
                                                                                                );
                                                                                                setCurrentStoryId(
                                                                                                    null
                                                                                                );
                                                                                                setNewStoryDescription(
                                                                                                    ''
                                                                                                );
                                                                                                setNewStoryAcceptanceCriteria(
                                                                                                    ''
                                                                                                );
                                                                                                setPriority(
                                                                                                    'medium'
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <XMarkIcon
                                                                                                className="h-6 w-6"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            <div className="mt-2">
                                                                                <textarea
                                                                                    placeholder="Description"
                                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-700 text-white"
                                                                                    value={
                                                                                        newStoryDescription
                                                                                    }
                                                                                    onChange={e =>
                                                                                        !isShare &&
                                                                                        !isLeadGen &&
                                                                                        setNewStoryDescription(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    readOnly={
                                                                                        isShare ||
                                                                                        isLeadGen
                                                                                    }
                                                                                />
                                                                                <textarea
                                                                                    placeholder="Acceptance Criteria"
                                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-700 text-white"
                                                                                    value={
                                                                                        newStoryAcceptanceCriteria
                                                                                    }
                                                                                    onChange={e =>
                                                                                        !isShare &&
                                                                                        !isLeadGen &&
                                                                                        setNewStoryAcceptanceCriteria(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    readOnly={
                                                                                        isShare ||
                                                                                        isLeadGen
                                                                                    }
                                                                                />
                                                                                <label className="block text-sm font-medium text-white mt-2">
                                                                                    Priority
                                                                                </label>
                                                                                <select
                                                                                    className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-700 text-white"
                                                                                    value={
                                                                                        priority
                                                                                    }
                                                                                    onChange={e =>
                                                                                        !isShare &&
                                                                                        !isLeadGen &&
                                                                                        setPriority(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        isShare ||
                                                                                        isLeadGen
                                                                                    }
                                                                                >
                                                                                    <option value="high">
                                                                                        high
                                                                                    </option>
                                                                                    <option value="medium">
                                                                                        medium
                                                                                    </option>
                                                                                    <option value="low">
                                                                                        low
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {!isShare &&
                                                                    !isLeadGen && (
                                                                        <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                                            <button
                                                                                type="button"
                                                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                                                onClick={() =>
                                                                                    addNewStory(
                                                                                        currentStoryId
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    isLoading
                                                                                }
                                                                            >
                                                                                {isLoading ? (
                                                                                    <>
                                                                                        <svg
                                                                                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                        >
                                                                                            <circle
                                                                                                className="opacity-25"
                                                                                                cx="12"
                                                                                                cy="12"
                                                                                                r="10"
                                                                                                stroke="currentColor"
                                                                                                strokeWidth="4"
                                                                                            ></circle>
                                                                                            <path
                                                                                                className="opacity-75"
                                                                                                fill="currentColor"
                                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                            ></path>
                                                                                        </svg>
                                                                                        Saving
                                                                                    </>
                                                                                ) : (
                                                                                    'Save'
                                                                                )}
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-gray-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                                                onClick={() => {
                                                                                    setShowAddStoryDialog(
                                                                                        false
                                                                                    );
                                                                                    setCurrentStoryId(
                                                                                        null
                                                                                    );
                                                                                    setNewStoryDescription(
                                                                                        ''
                                                                                    );
                                                                                    setNewStoryAcceptanceCriteria(
                                                                                        ''
                                                                                    );
                                                                                    setPriority(
                                                                                        'medium'
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {createdTime && (
                                                <div>
                                                    <h3 className="text-md font-medium leading-6 text-white">
                                                        Created time
                                                    </h3>
                                                    <p className="mt-1 text-sm text-gray-400 bg-transparent border-none p-2 w-full focus:bg-gray-800 focus:outline-none focus:ring-0 focus:border-transparent hover:bg-gray-800 transition-colors duration-200 rounded-md cursor-pointer">
                                                        {createdTime}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </div>
            <SubtasksDialog
                showSubtasksDialog={showSubtasksDialog}
                setShowSubtasksDialog={setShowSubtasksDialog}
                setOpen={setOpen}
                subtasks={subtasks}
                roles={roles}
                projectId={projectId}
                storyId={currentStoryId}
            />
        </Dialog>
    );
}
