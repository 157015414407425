import React, { useState, useEffect, useMemo, useRef } from 'react';
import Modal from '../../../Components/Modal';
import { getUserProfile } from '../../../../redux/actions/solo/getUserProfile';
import { updateUserMetadata } from '../../../../redux/actions/solo/updateUserMetadata';
import { useDispatch } from 'react-redux';
import { PhoneVerification } from '../../../CreateNewProject/PhoneVerification';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import {
    selectProfile,
    selectSubscriptionLinks
} from '../../../../redux/reducers/generic/profile';
import { BusinessType } from './BusinessType';
import { formFields, businessFields } from './fields';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { getCompletionPercentage } from '../Profile';
import { LinkedInVerification } from './LinkedInVerification';

export function ProfileModal({ open, onClose }) {
    const profile = useSelector(selectProfile);
    const dispatch = useDispatch();
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [formData, setFormData] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const { isSubbed } = useSubscriptionPayment();

    const completionPercentage = getCompletionPercentage(profile);
    const subscriptionLinks = useSelector(selectSubscriptionLinks);
    let soloPlusLink =
        subscriptionLinks?.find(
            link => link.tier === 'SoloPlus' && link.duration === 'monthly'
        )?.url + '?prefilled_promo_code=SOLOPLUS';

    useEffect(() => {
        if (profile) {
            const data = profile;
            console.log({ data });
            setFormData({
                linkedinURL: data.linkedinURL,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                schedulingLink: data.schedulingLink,
                businessType: data.businessType,
                businessName: data.businessName,
                businessURL: data.businessURL
            });
            if (data.phoneNumber) {
                setIsPhoneVerified(true);
            }
        }
    }, [profile]);

    useEffect(() => {
        let get_profile = async () => {
            await dispatch(getUserProfile());
        };
        get_profile();
    }, []);

    const hasChanges = useMemo(() => {
        if (!profile) return false;
        return Object.keys(formData).some(key => {
            const formValue = formData[key] || '';
            const profileValue = profile[key] || '';
            if (key === 'phoneNumber' && formValue !== profileValue) {
                return isPhoneVerified;
            }
            return formValue !== profileValue;
        });
    }, [formData, profile, isPhoneVerified]);

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handlePhoneNumberChange = value => {
        setFormData(prev => ({
            ...prev,
            phoneNumber: value
        }));
    };

    const onUpdate = async fields => {
        try {
            if (!isPhoneVerified) {
                const fieldsWithoutPhone = fields.filter(
                    field => field.key !== 'phoneNumber'
                );
                fields = fieldsWithoutPhone;
            }
            await dispatch(updateUserMetadata(fields));
            await dispatch(getUserProfile());
            toast.success('Profile updated successfully');
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsSaving(true);
        try {
            const fields = Object.entries(formData).map(([key, value]) => ({
                key,
                value: value?.toString()
            }));
            await onUpdate(fields);
        } catch (err) {
            // toast.error(err.message);
        } finally {
            setIsSaving(false);
        }
    };

    const handleClose = () => {
        if (hasChanges) {
            if (
                window.confirm(
                    'Are you sure you want to close? Any unsaved changes will be lost.'
                )
            ) {
                onClose();
            }
        } else {
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={handleClose} title="Edit Profile">
            <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                <LinkedInVerification />

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Phone Number
                    </label>
                    <PhoneVerification
                        phoneNumber={formData.phoneNumber}
                        setPhoneNumber={handlePhoneNumberChange}
                        isPhoneVerified={isPhoneVerified}
                        setIsPhoneVerified={setIsPhoneVerified}
                    />
                </div>

                {formFields.map(field => (
                    <FormField
                        key={field.name}
                        label={field.label}
                        name={field.name}
                        type={field.type}
                        value={formData[field.name]}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                    />
                ))}

                {/* Business Type */}
                <BusinessType formData={formData} setFormData={setFormData} />

                {businessFields.map(field => (
                    <FormField
                        key={field.name}
                        label={field.label}
                        name={field.name}
                        type={field.type}
                        value={formData[field.name]}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                    />
                ))}

                <div className="flex justify-between gap-2">
                    <SoloPlusUpgrade
                        completionPercentage={completionPercentage}
                        isSubbed={isSubbed}
                        soloPlusLink={soloPlusLink}
                    />
                    <button
                        type="submit"
                        disabled={!hasChanges || isSaving}
                        className={`rounded-md h-fit px-4 py-2 text-white ${
                            hasChanges && !isSaving
                                ? 'bg-blue-600 hover:bg-blue-700'
                                : 'bg-blue-300 cursor-not-allowed'
                        }`}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

function FormField({ label, name, type, value, onChange, placeholder }) {
    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
            />
        </div>
    );
}

const SoloPlusUpgrade = ({ completionPercentage, isSubbed, soloPlusLink }) => {
    return (
        <div>
            {completionPercentage === 100 && !isSubbed && (
                <a
                    href={soloPlusLink}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-teal-600 to-cyan-600 rounded-md shadow-sm hover:from-teal-700 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all duration-200 transform hover:scale-105"
                >
                    <span className="mr-2">🎁</span>
                    Activate Solo Plus
                </a>
            )}

            {!isSubbed && completionPercentage !== 100 && (
                <div className="flex flex-col gap-2 px-4 py-2 bg-indigo-50 rounded-lg border-2 border-indigo-200">
                    <div className="flex items-center gap-2">
                        <span className="font-semibold text-indigo-900 text-base">
                            Complete your profile to get Solo Plus free!
                        </span>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <span className="text-sm font-bold text-indigo-600 self-end">
                            {completionPercentage}% Complete
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};
