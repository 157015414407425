import { client } from '../../../api/client';
import { GRAPHS_BY_VERSION } from '../../../api/graphQL/solo/query';
import {
    setGraphVersion,
    setGraphVersionLoading,
    setProjectArchitecture
} from '../../reducers/generic/projectArchitecture';

export const getGraphsByVersion = (projectId, version) => async dispatch => {
    try {
        dispatch(setGraphVersionLoading(true));
        const { data } = await client.query({
            query: GRAPHS_BY_VERSION,
            variables: { projectId, version },
            fetchPolicy: 'network-only'
        });
        if (data.graphsByVersion) {
            dispatch(
                setProjectArchitecture({
                    graphs: data.graphsByVersion
                })
            );
        }
        dispatch(setGraphVersion(version));
        dispatch(setGraphVersionLoading(false));
    } catch (error) {
        console.log({ error });
        dispatch(setGraphVersionLoading(false));
    }
};
