import { createSlice } from '@reduxjs/toolkit';
import { getGraphData } from '../../../helpers/graph';

const initialState = {
    seen: true,
    loading: false,
    currentProjectId: null,
    currentQuestionIndex: null,
    error: null,
    highlightNodes: [],
    highlightLinks: [],
    graphVersion: null,
    graphVersionLoading: false,
    graphs: [],
    currentNode: null,
    currentEdge: null,
    panelResized: 0,
    modal: false,
    selectedNode: null,
    uiView: null,
    gettingUIView: false,
    uiViewLoading: false,
    uiViewEditing: false,
    freeNodeLabel: null
};

function findFreeNodeLabel(graphs) {
    const frontendGraph = graphs.find(g => g.side === 'frontend');
    if (!frontendGraph) {
        return null;
    }
    const coreNode = frontendGraph.nodes.find(
        node => node.id.toLowerCase() === 'core'
    );
    if (!coreNode) {
        return null;
    }

    const connectedNode = frontendGraph.edges.find(
        edge => edge.source === 'core'
    );
    if (!connectedNode) {
        return null;
    }

    const connectedNodeId = connectedNode.target;

    const connectedNodeData = frontendGraph.nodes.find(
        node => node.id === connectedNodeId
    );

    if (!connectedNodeData) {
        console.log('Connected node data not found');
        return null;
    }

    return connectedNodeData.data.label;
}

export const projectArchitectureSlice = createSlice({
    name: 'projectArchitecture',
    initialState,
    reducers: {
        setPanelResized: (state, action) => {
            state.panelResized = state.panelResized + 1;
        },
        setProjectArchitectureSeen: (state, action) => {
            state.seen = action.payload;
        },
        setShowNodeDetailModal: (state, action) => {
            state.modal = !state.modal;
            state.selectedNode = action.payload;
        },
        gettingUIView: (state, action) => {
            state.gettingUIView = action.payload;
        },
        setUIView: (state, action) => {
            state.uiView = action.payload;
        },
        setUIViewEditing: (state, action) => {
            state.uiViewEditing = action.payload;
        },
        waitingForProjectArchitecture: (state, action) => {
            state.loading = action.payload;
        },
        waitingForUIView: (state, action) => {
            state.uiViewLoading = action.payload;
        },
        setGraphVersionLoading: (state, action) => {
            state.graphVersionLoading = action.payload;
        },
        clearStateProjectArchitecture: state => {
            state.graphs = [];
            state.error = null;
            state.loading = false;
            state.highlightLinks = [];
            state.highlightNodes = [];
            state.currentQuestionIndex = null;
            state.currentProjectId = null;
            state.graphVersion = null;
            state.currentNode = null;
            state.currentEdge = null;
            state.panelResized = 0;
            state.modal = false;
            state.selectedNode = null;
            state.uiView = null;
            state.gettingUIView = false;
            state.uiViewLoading = false;
            state.uiViewEditing = false;
            state.freeNodeLabel = null;
        },
        setGraph: (state, action) => {
            let graph = action.payload;
            if (graph.version) state.graphVersion = graph.version;
            graph = getGraphData(graph);
            let set = false;
            state.graphs = state.graphs.map(g => {
                if (g.side === graph.side) {
                    set = true;
                    return graph;
                }
                return g;
            });
            if (!set) {
                state.graphs.push(graph);
            }
            state.freeNodeLabel = findFreeNodeLabel(state.graphs);
        },
        setGraphVersion: (state, action) => {
            state.graphVersion = action.payload;
        },
        setProjectArchitecture: (state, action) => {
            try {
                let { graphs } = action.payload;
                let newGraphs = [];
                for (let i = 0; i < graphs.length; i++) {
                    let new_graph = getGraphData(graphs[i]);
                    newGraphs.push(new_graph);
                }
                if (graphs && graphs[0]?.version)
                    state.graphVersion = graphs[0].version;
                state.graphs = newGraphs;
                state.freeNodeLabel = findFreeNodeLabel(newGraphs);
            } catch (error) {
                console.log({ error });
            }
        },
        setHighlightNodes: (state, action) => {
            if (action.payload.is_hover) {
                state.currentNode = action.payload;
                state.highlightNodes = [action.payload.id];
            } else {
                state.highlightNodes = action.payload;
                state.currentNode = null;
            }
        },
        setHighlightLinks: (state, action) => {
            if (action.payload.is_hover) {
                state.currentEdge = action.payload;
                state.highlightLinks = [action.payload.id];
            } else {
                state.highlightLinks = action.payload;
                state.currentEdge = null;
            }
        },
        clearHighlights: state => {
            state.highlightLinks = [];
            state.highlightNodes = [];
        }
    }
});

export const {
    setGraph,
    waitingForProjectArchitecture,
    waitingForUIView,
    setProjectArchitecture,
    clearStateProjectArchitecture,
    setProjectArchitectureSeen,
    setShowNodeDetailModal,
    setHighlightNodes,
    setHighlightLinks,
    clearHighlights,
    setCurrentNode,
    setUIView,
    setGraphVersionLoading,
    setPanelResized,
    setGraphVersion,
    gettingUIView,
    setUIViewEditing
} = projectArchitectureSlice.actions;

export const selectProjectArchitecture = state => state.projectArchitecture;
export const selectProjectArchitectureSeen = state =>
    state.projectArchitecture.seen;
export const selectProjectArchitectureLoading = state =>
    state.projectArchitecture.loading;
export const selectCurrentNode = state => state.projectArchitecture.currentNode;
export const selectCurrentEdge = state => state.projectArchitecture.currentEdge;
export const selectUIViewEditing = state =>
    state.projectArchitecture.uiViewEditing;
export const selectPanelResized = state =>
    state.projectArchitecture.panelResized;
export const selectGraphVersion = state =>
    state.projectArchitecture.graphVersion;
export const selectGraphVersionLoading = state =>
    state.projectArchitecture.graphVersionLoading;
export const selectShowNodeDetailModal = state =>
    state.projectArchitecture.modal;
export const selectSelectedNode = state =>
    state.projectArchitecture.selectedNode;
export const selectUIView = state => state.projectArchitecture.uiView;
export const selectGettingUIView = state =>
    state.projectArchitecture.gettingUIView;
export const selectUIViewLoading = state =>
    state.projectArchitecture.uiViewLoading;
export const selectFreeNodeLabel = state =>
    state.projectArchitecture.freeNodeLabel;
export default projectArchitectureSlice.reducer;
