import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { getUserProfile } from '../../../redux/actions/solo/getUserProfile';
import { ProfileModal } from './ProfileModal';
import {
    selectProfile,
    selectProfileModalOpen,
    setProfileModalOpen
} from '../../../redux/reducers/generic/profile';
import { useSelector } from 'react-redux';
import { getSoloSubscriptionLinks } from '../../../redux/actions/solo/getSoloStripeLink';
import { useSearchParams } from 'react-router-dom';

export const getCompletionPercentage = profile => {
    if (!profile) return 0;
    const fields = [
        'phoneNumber',
        'firstName',
        'lastName',
        'linkedinURL',
        // 'schedulingLink',
        'businessType',
        'businessName',
        'businessURL'
    ];
    const completedFields = fields.filter(field => profile[field]?.length > 0);
    return Math.round((completedFields.length / fields.length) * 100);
};

export default function Profile() {
    const isProfileModalOpen = useSelector(selectProfileModalOpen);
    const profile = useSelector(selectProfile);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('profile')) {
            dispatch(setProfileModalOpen(true));
        }
    }, [searchParams]);

    useEffect(() => {
        const fetchProfile = async () => {
            await dispatch(getUserProfile());
        };
        fetchProfile();
    }, [dispatch]);

    async function getSubscriptionLinks() {
        await dispatch(getSoloSubscriptionLinks());
    }
    useEffect(() => {
        getSubscriptionLinks();
    }, []);

    const getCompletionColor = percentage => {
        if (percentage < 30) return 'text-red-500';
        if (percentage < 70) return 'text-yellow-500';
        return 'text-green-500';
    };

    const completionPercentage = getCompletionPercentage(profile);

    return (
        <>
            <button
                onClick={() => {
                    dispatch(setProfileModalOpen(true));
                    setSearchParams({ profile: 'true' });
                }}
                className="flex w-fit h-fit flex-row items-center gap-1 text-sm font-bold rounded-full border border-slate-200 p-2 transition-colors duration-200 hover:bg-slate-100 dark:hover:bg-slate-800"
            >
                <UserCircleIcon className="h-6 w-6 text-black dark:text-white" />
                <div className="flex flex-col items-start">
                    <h1>Profile</h1>
                    <span
                        className={`text-2xs ${getCompletionColor(
                            completionPercentage
                        )}`}
                    >
                        {completionPercentage}% Complete
                    </span>
                </div>
            </button>

            <ProfileModal
                open={isProfileModalOpen}
                onClose={() => dispatch(setProfileModalOpen(false))}
            />
        </>
    );
}
