import React, { useState } from 'react';
import { CurrencyDollarIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { useDispatch } from 'react-redux';
import { upgradeToLeadGenTier } from '../../../redux/actions/enterprise/upgradeToLeadGenTier';
import { purchaseLeadGenCredits } from '../../../redux/actions/enterprise/purchaseLeadGenCredits';

interface PricingTier {
    credits: number;
    price: number;
    tier: string;
}

const pricingTiers: PricingTier[] = [
    { credits: 25, price: 24900, tier: 'LeadGenBasic' },
    { credits: 50, price: 49900, tier: 'LeadGenPlus' },
    { credits: 100, price: 99900, tier: 'LeadGenPro' },
    { credits: 200, price: 199900, tier: 'LeadGenUltimate' }
];

const PurchaseCreditsModal: React.FC<{ onClose: () => void }> = ({
    onClose
}) => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { leadGenCreditsLeft, leadGenSubscriptionStatus } =
        enterpriseOrganization?.leadGenData || {};

    const [selectedTier, setSelectedTier] = useState<PricingTier | null>(null);
    const [customCredits, setCustomCredits] = useState<number>(0);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [agreeToPlatformFee, setAgreeToPlatformFee] = useState<boolean>(true);
    const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleTierSelect = (tier: PricingTier) => {
        if (!isCurrentPlan(tier.tier)) {
            setSelectedTier(tier);
            setCustomCredits(0);
            setIsConfirming(true);
        }
    };

    const handleCustomCreditsChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = parseInt(e.target.value);
        setCustomCredits(isNaN(value) ? 0 : value);
        setSelectedTier(null);
        setIsConfirming(false);
    };

    const handlePurchase = async () => {
        setPurchaseLoading(true);
        if (customCredits > 0) {
            await dispatch<any>(
                purchaseLeadGenCredits({
                    enterpriseOrganizationId:
                        enterpriseOrganization?._id.toString(),
                    quantity: customCredits
                })
            );
        } else if (selectedTier) {
            await dispatch<any>(
                upgradeToLeadGenTier({
                    enterpriseOrganizationId:
                        enterpriseOrganization?._id.toString(),
                    newTier: selectedTier.tier
                })
            );
        }
        setPurchaseLoading(false);
        try {
            onClose();
        } catch (err) {
            console.log({ err });
        }
    };

    const isCurrentPlan = (tier: string) => {
        return leadGenSubscriptionStatus?.tier === tier;
    };

    const displayedTiers = pricingTiers.filter(tier =>
        tier.tier === 'LeadGenBasic' ? isCurrentPlan('LeadGenBasic') : true
    );

    return (
        <div className="space-y-6">
            <div className="text-center">
                <SparklesIcon className="mx-auto h-12 w-12 text-blue-600" />
                <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
                    Purchase Lead Generation Credits
                </h2>
                <p className="mt-2 text-lg text-gray-600">
                    Choose a package or customize your credit purchase to
                    supercharge your lead generation efforts.
                </p>
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                {displayedTiers.map(tier => (
                    <div
                        key={tier.credits}
                        className={`relative rounded-lg border p-6 shadow-md transition-all duration-300 ${
                            isCurrentPlan(tier.tier)
                                ? 'border-black cursor-not-allowed'
                                : selectedTier === tier
                                  ? 'border-blue-500 ring-2 ring-blue-500'
                                  : 'border-gray-300 hover:border-blue-300 hover:shadow-lg'
                        }`}
                        onClick={() => handleTierSelect(tier)}
                    >
                        <div className="flex flex-col justify-between h-full">
                            <div>
                                <h3 className="text-xl font-bold text-gray-900">
                                    {tier.credits} Credits
                                </h3>
                                <p className="mt-2 text-3xl font-semibold text-blue-600">
                                    ${tier.price / 100}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    per month
                                </p>
                                {isCurrentPlan(tier.tier) && (
                                    <p className="mt-2 text-sm font-medium text-blue-600">
                                        Current Plan
                                    </p>
                                )}
                            </div>
                            <button
                                className={`mt-6 w-full rounded-md px-4 py-2 text-sm font-semibold shadow-sm transition-colors duration-300 ${
                                    isCurrentPlan(tier.tier)
                                        ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                                        : selectedTier === tier
                                          ? 'bg-blue-600 text-white hover:bg-blue-700'
                                          : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                                }`}
                                disabled={isCurrentPlan(tier.tier)}
                            >
                                {isCurrentPlan(tier.tier)
                                    ? 'Current Plan'
                                    : selectedTier === tier
                                      ? 'Selected'
                                      : 'Select Plan'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {leadGenSubscriptionStatus?.status === 'active' && (
                <div className="mt-6">
                    <label
                        htmlFor="custom-credits"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Or purchase custom amount ($15 per credit)
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <CurrencyDollarIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            type="number"
                            name="custom-credits"
                            id="custom-credits"
                            className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-20 sm:text-sm border-gray-300 rounded-md"
                            placeholder="Enter number of credits"
                            value={customCredits || ''}
                            onChange={handleCustomCreditsChange}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span
                                className="text-gray-500 sm:text-sm"
                                id="price-currency"
                            >
                                Credits
                            </span>
                        </div>
                    </div>
                    {customCredits > 0 && (
                        <p className="mt-2 text-sm text-gray-600">
                            Total: ${customCredits * 15}
                        </p>
                    )}
                </div>
            )}

            {isConfirming && selectedTier && (
                <div className="mt-6 p-4 bg-blue-50 rounded-md">
                    <p className="text-sm text-blue-700">
                        You are about to purchase {selectedTier.credits} credits
                        for ${selectedTier.price / 100} per month. If you're
                        already subscribed to a plan, this will add the credits
                        instantly and start the new monthly plan from today.
                    </p>
                </div>
            )}

            <div className="mt-6 flex items-center border border-gray-300 rounded-md p-2">
                <input
                    id="agree-platform-fee"
                    name="agree-platform-fee"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={agreeToPlatformFee}
                    onChange={e => setAgreeToPlatformFee(e.target.checked)}
                />
                <label
                    htmlFor="agree-platform-fee"
                    className="ml-2 block text-xs text-gray-900"
                >
                    I understand that pre.dev will take 10% of any closed
                    project upon completion of payment from the client. By
                    checking this box, I agree to the{' '}
                    <a
                        href="https://app.termly.io/policy-viewer/policy.html?policyUUID=cca10b20-556c-4cf1-ad82-bc030a653363#purchases"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                    >
                        terms of purchase
                    </a>
                    .
                </label>
            </div>

            <div className="mt-8">
                <button
                    onClick={handlePurchase}
                    disabled={
                        (!selectedTier && customCredits === 0) ||
                        !agreeToPlatformFee ||
                        purchaseLoading ||
                        (selectedTier && isCurrentPlan(selectedTier.tier))
                    }
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                        (selectedTier || customCredits > 0) &&
                        agreeToPlatformFee &&
                        !purchaseLoading
                            ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                            : 'bg-gray-300 cursor-not-allowed'
                    }`}
                >
                    {purchaseLoading ? (
                        <span className="flex items-center">
                            <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            Processing...
                        </span>
                    ) : customCredits > 0 ? (
                        'Purchase Custom Credits'
                    ) : isConfirming ? (
                        'Confirm Purchase'
                    ) : (
                        'Purchase Credits'
                    )}
                </button>
            </div>
        </div>
    );
};

export default PurchaseCreditsModal;
