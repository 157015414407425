import { useSelector, useDispatch } from 'react-redux';
import { selectSow } from '../../../../redux/reducers/generic/sow';
import { FaDollarSign } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useState } from 'react';
import Modal from '../../../Components/Modal';
import PricingSection from './PricingSection';
import { useProject } from '../../../../context-providers/Project';
import { Icon } from '../TipTap/components/ui/Icon';
import { Spinner } from '../../../Components/Spinner';

export const PricingEstimate = ({ showText }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const sow = useSelector(selectSow);
    const { project } = useProject(); // Destructure project from useProject
    const type = sow?.type || 'mvp';

    const handleClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (type !== 'mvp') return null;

    return (
        <div className={'flex items-center'}>
            {!sow?.completed && project.firstGeneration && showText ? (
                <button className="flex gap-x-2 flex-row py-2 px-4 justify-center items-center text-yellow-400 border border-yellow-500 rounded-full hover:bg-yellow-50 focus:outline-none focus:ring-2 focus:ring-yellow-300 transition-colors duration-200">
                    <Spinner w="4" color="#D9A300" />
                    Loading Pricing...
                </button>
            ) : (
                sow?.completed && (
                    <>
                        <button
                            onClick={handleClick}
                            data-tooltip-id={'pricing-estimate-tooltip'}
                            data-tooltip-content="View Pricing Estimate"
                            className="flex flex-row px-4 py-2 gap-x-2 justify-center items-center text-yellow-400 border border-yellow-500 rounded-full hover:bg-yellow-50 focus:outline-none focus:ring-2 focus:ring-yellow-300 transition-colors duration-200"
                        >
                            <FaDollarSign className="h-4" />
                            {showText && (
                                <span className="font-bold">
                                    Pricing Estimate
                                </span>
                            )}
                        </button>
                        <Tooltip id={'pricing-estimate-tooltip'} place="top" />
                        <Modal
                            open={isModalOpen}
                            onClose={handleCloseModal}
                            maxWidth="max-w-2xl"
                            // title="Pricing Estimate"
                            bgColor="bg-black dark"
                        >
                            <PricingSection />
                        </Modal>
                    </>
                )
            )}
        </div>
    );
};
