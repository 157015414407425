import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectShowRequestModal,
    setMeetingRequested,
    setShowRequestModal
} from '../../../redux/reducers/generic/project';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { requestMeeting } from '../../../redux/actions/solo/requestMeeting';
import { useParams } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { salesCalendlyLink } from '../../../constants';
import trackEvent from '../../../helpers/trackEvent';

export default function RequestModal() {
    const open = useSelector(selectShowRequestModal);
    const { accessTokenPayload } = useSessionContext();
    const { projectId } = useParams();

    const dispatch = useDispatch();

    const [completed, setCompleted] = useState(false);

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => {
            console.log('onEventScheduled', e);
            dispatch(requestMeeting(projectId));
            setCompleted(true);
            dispatch(setMeetingRequested(true));

            //tracking events for meeting scheduled
            trackEvent('meetingScheduledWithPreDev', {
                userEmail: accessTokenPayload?.email,
                userId: accessTokenPayload?.userId,
                conversionId: projectId,
                projectId
            });
        }
    });

    const calendlyWidget = (
        <InlineWidget
            url={'https://calendly.com/pre_dev/free-expert-consultation'}
            prefill={{
                email: accessTokenPayload?.email
            }}
            styles={{
                maxHeight: '865px',
                height: '865px'
            }}
        />
    );

    const successMessage = (
        <>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                    />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                    >
                        Meeting Request Successful!
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            We will be reaching out to soon to finalize the
                            project requirements and begin building!
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => dispatch(setShowRequestModal(false))}
                >
                    Continue Refining the Project
                </button>
            </div>
        </>
    );

    return (
        <Modal
            open={open}
            onClose={() => dispatch(setShowRequestModal(false))}
            maxWidth="max-w-2xl z-50"
        >
            {completed ? successMessage : calendlyWidget}
        </Modal>
    );
}
