import React, { useState, useEffect } from 'react';
import { SowVersions } from './SoWVersions';
import { CustomDocsToggle } from './CustomDocsToggle';
import {
    selectProjectId,
    selectSowVersion
} from '../../../../redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import ThumbsUpDown from '../../../Components/ThumbsUpDown';
import { selectSow } from '../../../../redux/reducers/generic/sow';

export const TopHeader = ({
    isEnterprise,
    isSidebarOpen,
    titleOverride = null
}) => {
    const projectId = useSelector(selectProjectId);
    const sowVersion = useSelector(selectSowVersion);
    const sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const thumbsId = `${projectId}-docs-${type}-${sowVersion[type]}`;
    return (
        <div className="w-full sticky top-0 z-2 py-2 px-2 bg-gray-50 rounded-lg">
            <div className="flex flex-col grid grid-cols-3 mx-2 items-center">
                <div className="hidden sm:flex">
                    {!isSidebarOpen && (
                        <CustomDocsToggle
                            type={type}
                            isEnterprise={isEnterprise}
                        />
                    )}
                </div>
                <div className="flex flex-row gap-x-2 items-center justify-center mx-auto w-full text-center">
                    <img
                        src="/predevblack.png"
                        className="justify-center w-10 sm:w-14 rounded p-1"
                        alt="Company Logo"
                    />
                    <h2 className="text-xl sm:text-2xl tracking-tight font-extrabold leading-tight text-gray-900">
                        {titleOverride
                            ? titleOverride
                            : isSidebarOpen || windowWidth <= 1300
                              ? 'Docs'
                              : 'Documentation'}
                    </h2>
                </div>
                <div className="sm:hidden flex"></div>

                <div className="flex justify-between items-center">
                    <div className="flex justify-end w-full mr-0 gap-x-2">
                        {sow?.completed && !isSidebarOpen && (
                            <ThumbsUpDown
                                id={thumbsId}
                                metadata={[`${type}-docs`]}
                                baseColor="gray-500"
                            />
                        )}

                        {type && <SowVersions type={type} />}
                    </div>
                </div>
            </div>
        </div>
    );
};
