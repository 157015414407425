import { useEffect } from 'react';
import { HeroExperience } from './HeroExperience';
import ClientSection from './client-section';
import PricingSection from '../Pricing/pricing-section';
import { SphereMask } from '../Components/magicui/sphere-mask';
import { CtaSection } from './cta-section';
import { FeaturesSection } from './FeaturesSection';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { setRedirectURL } from '../../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import RadialGradient from '../Components/magicui/RadialGradient';
import HeroSection from './hero-section';
import { ParticlesEffect } from './HeroExperience/ParticlesEffect';
import { FAQ } from './faq';

import { useEnterpriseOrganizations } from '../../context-providers/EnterpriseOrganizations';
import { HowItWorks } from './HowItWorks';
import Leaderboard from '../Leaderboard';
import FeatureLeaderboard from '../Leaderboard/feature_leaderboard';
import { TestimonialSection } from './testimonials-section';

export const LandingPage = () => {
    const sessionContext = useSessionContext();
    const { enterpriseOrganizations } = useEnterpriseOrganizations();
    const navigate = useNavigate();
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element && window.scrollY === 0) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [hash]);

    const onGetStarted = () => {
        let redirectURL = '/projects';
        if (enterpriseOrganizations.length > 0) {
            redirectURL = '/enterprise/dashboard';
        }
        setRedirectURL(redirectURL);
        if (sessionContext.doesSessionExist === true) {
            navigate(redirectURL);
        } else {
            navigate('/auth/?redirectToPath=%2Fprojects');
        }
    };

    useEffect(() => {
        if (pathname === '/how-it-works') {
            navigate('/#how-it-works');
        }
    }, [pathname]);

    return (
        <div className="bg-black">
            <div className="max-w-screen-xl mx-auto">
                <ParticlesEffect />
                <RadialGradient />
            </div>

            {pathname === '/leaderboard' ? (
                <Leaderboard />
            ) : (
                <>
                    <HeroExperience />
                    <div className="dark max-w-screen-xl mx-auto mt-16">
                        <HeroSection onGetStarted={onGetStarted} />
                        <FeaturesSection />
                        <TestimonialSection />
                        <ClientSection />
                        <SphereMask />
                        <PricingSection />
                        <FAQ />
                        <CtaSection />
                    </div>
                    <div className="fixed">
                        <ParticlesEffect />
                    </div>
                </>
            )}
        </div>
    );
};
