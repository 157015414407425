import { useProject } from '../../../context-providers/Project';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { toggleSubscribeModal } from '../../../redux/reducers/generic/billing';
import { ScheduleLink } from './scheduleLink';
import { BudgetData } from './BudgetData';
import { QuoteMode } from './quoteMode';
import { MatchWithDev } from './matchWithDev';
import { Header } from './header';
import { UrgencySlider } from './Urgency';
import { setUrgency } from '../../../redux/actions/solo/setUrgency';
import { updateMatchWithDev } from '../../../redux/actions/solo/updateMatchWithDev';
import { useSearchParams } from 'react-router-dom';

export const Budget = ({ isMobile }) => {
    const { project } = useProject();
    const { isEnterprise, isSubbed, isSolo, isHomePage } =
        useSubscriptionPayment();

    const [isMinimized, setIsMinimized] = useState(() => {
        const storedValue = window.localStorage.getItem('budgetMinimized');
        return storedValue ? JSON.parse(storedValue) : false;
    });
    const [urgency, setUrgencyState] = useState(project.urgency || 0);
    const dispatch = useDispatch();
    const [debouncedUrgency, setDebouncedUrgency] = useState(urgency);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        async function handleDisableMatch() {
            if (
                searchParams.get('disableMatchWithDev') &&
                project.matchWithDev
            ) {
                setMatchLoading(true);
                await dispatch(updateMatchWithDev(project._id, false));
                setMatchLoading(false);
            }
        }
        handleDisableMatch();
    }, [searchParams, project, dispatch, project._id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedUrgency(urgency);
        }, 500);

        return () => clearTimeout(timer);
    }, [urgency]);

    useEffect(() => {
        if (debouncedUrgency !== project.urgency) {
            dispatch(setUrgency(project._id, debouncedUrgency));
        }
    }, [debouncedUrgency, project._id]);

    const handleUrgencyChange = newUrgency => {
        setUrgencyState(newUrgency);
    };

    let [matchLoading, setMatchLoading] = useState(false);

    const setMatchWithDev = async value => {
        setMatchLoading(true);
        await dispatch(updateMatchWithDev(project._id, value));
        setMatchLoading(false);
    };

    return (
        <div
            className={`w-fit bg-gradient-to-br from-gray-900 to-black max-w-2xl rounded-lg shadow-lg my-2 mx-auto border-2 border-gray-700 py-4 px-4 ${
                !isMobile ? 'fixed bottom-[0px] right-[5px] z-[10]' : ''
            }`}
        >
            <Header
                isMinimized={isMinimized}
                setIsMinimized={setIsMinimized}
                matchWithDev={project.matchWithDev}
                setMatchWithDev={setMatchWithDev}
                matchLoading={matchLoading}
            />
            {!isMinimized && (
                <div
                    className={`overflow-hidden gap-y-4 flex flex-col transition-all duration-400 ease-in-out ${
                        isMinimized ? 'max-h-0 opacity-0' : 'mt-6 opacity-100'
                    }`}
                >
                    <BudgetData />

                    <div className="flex justify-between gap-x-2 gap-y-2">
                        <div className="w-full">
                            {isEnterprise ? (
                                <QuoteMode />
                            ) : (
                                <MatchWithDev
                                    matchWithDev={project.matchWithDev}
                                    setMatchWithDev={setMatchWithDev}
                                    matchLoading={matchLoading}
                                />
                            )}
                        </div>
                    </div>
                    {project.matchWithDev && (isSolo || isHomePage) && (
                        <div className="flex flex-col gap-y-4">
                            {isSolo && !isSubbed && <UpgradeToSoloPlus />}
                            <ScheduleLink />
                            <UrgencySlider
                                urgency={urgency}
                                setUrgency={handleUrgencyChange}
                                textColor="text-white"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export const UpgradeToSoloPlus = () => {
    const dispatch = useDispatch();
    return (
        <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-lg p-3 shadow-lg">
            <p className="text-white text-sm font-semibold">
                Jump to the Front of the Queue
            </p>
            <p className="text-xs text-gray-100 mt-1">
                Get priority access to developer agencies
            </p>
            <button
                onClick={() => {
                    dispatch(toggleSubscribeModal());
                }}
                className="inline-block mt-2 px-4 py-2 bg-white text-indigo-600 text-xs font-medium rounded-full hover:bg-gray-100 transition duration-300"
            >
                Upgrade to Solo Plus
            </button>
        </div>
    );
};
