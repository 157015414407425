import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CREATE_PROJECT } from '../../../api/graphQL';
import { useNavigate } from 'react-router-dom';
import { addToProjects } from '../../../redux/reducers/generic/projects';
import { ContinueButton } from '../../CreateNewProject';
import UploadRFP from '../../CreateNewProject/UploadRFP';
import NewExistingProjectPicker from '../../CreateNewProject/NewExistingProjectPicker';
import { BudgetSlider } from '../../ProjectDashboard/Budget/BudgetSlider';
import { UrgencySlider } from '../../ProjectDashboard/Budget/Urgency';
import { MatchWithDev } from '../../ProjectDashboard/Budget/matchWithDev';

export function NewProjectInput() {
    const dispatch = useDispatch();
    const [newProjectName, setNewProjectName] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [createProject] = useMutation(CREATE_PROJECT);
    const navigate = useNavigate();
    const [fileKey, setFileKey] = useState(null);
    const [option, setOption] = useState('new');
    const [isUploading, setUploading] = useState(false);
    const [matchWithDev, setMatchWithDev] = useState(false);
    const [budget, setBudget] = useState(10000);
    const [urgency, setUrgency] = useState(0);

    async function handleNewProjectSubmit(e) {
        e.preventDefault();
        if (isUploading) return;
        if (newProjectName.trim() !== '') {
            setLoading(true);
            try {
                const newProject = await createProject({
                    variables: {
                        name: newProjectName,
                        type: 'other',
                        hasExistingContext: option === 'existing',
                        fileKeyS3: fileKey,
                        matchWithDev,
                        budget: budget.toString(),
                        urgency
                    }
                });
                navigate(`/projects/${newProject.data.createProject._id}`);
                dispatch(addToProjects(newProject.data.createProject));
            } catch (error) {
                console.error({ error });
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            try {
                document.getElementById('createNewProject1')?.focus();
            } catch (err) {
                console.error(err);
            }
        }, 100);
    }, []);

    return (
        <div>
            <form
                onSubmit={handleNewProjectSubmit}
                className="mb-4 flex flex-col justify-center"
            >
                <NewExistingProjectPicker
                    option={option}
                    setOption={setOption}
                />
                <input
                    id="createNewProject1"
                    type="text"
                    placeholder="Project Name"
                    value={newProjectName}
                    onChange={e => setNewProjectName(e.target.value)}
                    className="border-gray-400 px-4 py-2 w-full outline-none"
                />

                <UploadRFP
                    setFileKey={setFileKey}
                    isUploading={isUploading}
                    setUploading={setUploading}
                />
                <div className="h-4" />

                <div className="flex flex-col gap-y-2">
                    <BudgetSlider
                        textColor="text-gray-300"
                        budget={budget}
                        setBudget={setBudget}
                    />

                    <MatchWithDev
                        matchWithDev={matchWithDev}
                        setMatchWithDev={setMatchWithDev}
                    />

                    {matchWithDev && (
                        <UrgencySlider
                            urgency={urgency}
                            setUrgency={setUrgency}
                            textColor="text-gray-300"
                        />
                    )}
                </div>

                <ContinueButton isLoading={isLoading} />
            </form>
        </div>
    );
}
