export const PricingHeader = ({
    freeTrialActivated
}: {
    freeTrialActivated: boolean;
}) => (
    <div className="mx-auto max-w-5xl text-center">
        {freeTrialActivated ? (
            <>
                <h4 className="text-xl font-bold tracking-tight text-black dark:text-white">
                    Special Offer
                </h4>
                <h2 className="text-5xl font-bold tracking-tight text-black dark:text-white sm:text-6xl">
                    <p>
                        Your first project on{' '}
                        <span className="text-blue-600">pre.dev</span> is free,
                        no credit card required.
                    </p>
                </h2>
            </>
        ) : (
            <>
                <h4
                    id="pricing"
                    className="text-xl font-bold tracking-tight text-black dark:text-white"
                >
                    Unlimited Projects
                </h4>
                <h2 className="text-5xl font-bold tracking-tight text-black dark:text-white sm:text-6xl">
                    Start building with{' '}
                    <span className="text-blue-600">pre.dev</span>
                </h2>
            </>
        )}
        <p className="mt-6 text-xl leading-8 text-black/80 dark:text-white">
            With <span className="text-blue-600">pre.dev</span>, you can go from
            a rough concept to full product architecture, roadmap, and
            boilerplate code in minutes instead of weeks. Manage multiple
            product/feature concepts and instantly adapt complex architectures
            to new ideas and unforeseen changes.
        </p>
    </div>
);
