import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    toggleQuoteMode,
    selectQuoteMode
} from '../../../redux/reducers/generic/sow';

export const QuoteMode = () => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const quoteMode = useSelector(selectQuoteMode);
    const handleFilterChange = e => {
        setChecked(e.target.checked);
        dispatch(toggleQuoteMode(e.target.checked));
    };

    useEffect(() => {
        setChecked(quoteMode);
    }, [quoteMode]);

    return (
        <div className="flex flex-col items-start space-y-2 bg-gradient-to-br from-gray-900 to-black rounded-lg shadow-lg border-2 border-gray-700 p-4">
            <div className="flex items-center">
                <input
                    type="checkbox"
                    id="quoteMode"
                    checked={checked}
                    onChange={handleFilterChange}
                    className="cursor-pointer"
                />
                <label
                    htmlFor="quoteMode"
                    className="text-xs text-gray-200 ml-2 cursor-pointer"
                >
                    Quote Mode
                </label>
            </div>
            <p className="text-xs text-gray-300">
                Hides sub-tasks and includes pricing.
            </p>
        </div>
    );
};
