import { useState } from 'react';
import Modal from '../../../Components/Modal';
import { Budget } from '../../Budget';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const BudgetButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isHomePage, isSolo } = useSubscriptionPayment();

    const handleOpenModal = () => {
        window.localStorage.setItem('budgetMinimized', JSON.stringify(false));
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <button
                onClick={handleOpenModal}
                className="flex items-center justify-center px-4 py-2 gap-x-2 bg-white text-black font-medium rounded-lg shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-300 ease-in-out"
            >
                <img src="/predevblack.png" alt="Budget" className="h-5" />
                {isSolo || isHomePage ? 'Budget & Hire Dev' : 'Budget'}
            </button>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                maxWidth="max-w-xl"
                bgColor="bg-black"
            >
                <div className="flex justify-center pt-4">
                    <Budget isMobile={true} />
                </div>
            </Modal>
        </>
    );
};
