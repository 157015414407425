import {
    Route,
    BrowserRouter as Router,
    Routes,
    useSearchParams
} from 'react-router-dom';
import { UserAuthForm } from './components/Auth/user-auth-form';
import { ProjectDashboard } from './components/ProjectDashboard';
import ChatWidgetPage from './components/WidgetPages/ChatWidgetPage';

import Home from './components/Home';
import { LandingPage } from './components/Landing';
import { EnterpriseLanding } from './components/EnterpriseLanding';
import { ApolloProvider } from '@apollo/client';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { ThirdpartyEmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { client } from './api/client';
import { EmailPasswordHeadersOverride, SuperTokensConfig } from './auth_config';
import { Forgot } from './components/Auth/forgot';
import { Reset } from './components/Auth/reset';
import { ProjectProvider } from './context-providers/Project';
import { ProjectsProvider } from './context-providers/Projects';
import { LoadingProvider } from './context-providers/Loading';
import { SubscriptionPaymentProvider } from './context-providers/SubscriptionPayment';
import { NewProject } from './components/Home/NewProject';
import { GraphProvider } from './context-providers/Graph';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
// import { Passport } from "./components/Passport";
import EnterpriseHome from './components/EnterpriseHome';
import { EnterpriseOrganizationsProvider } from './context-providers/EnterpriseOrganizations';
import { EnterpriseOrganizationProvider } from './context-providers/EnterpriseOrganization';
import NewOrganization from './components/EnterpriseHome/NewOrganization';
import { ClientProjects } from './components/EnterpriseHome/ClientProjects';
import { WidgetManagement } from './components/EnterpriseHome/WidgetManagement';
import { EnterpriseUsers } from './components/EnterpriseHome/EnterpriseUsers';
import { Footer } from './components/Footer';
import Widget from './components/WidgetPages/ChatWidgetPage/Widget';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { PreBuiltUIList } from './auth_config';
import CreateNewProject from './components/CreateNewProject';
import MainHeader from './components/WidgetPages/ChatWidgetPage/Widget/MainHeader';
import { useEnterpriseOrganization } from './context-providers/EnterpriseOrganization';
import { Outlet } from 'react-router-dom';
import posthog from 'posthog-js';
import { Toaster } from 'sonner';
import { Loading } from './components/Components/Loading';
import { KnowledgeManagement } from './components/EnterpriseHome/KnowledgeManagement';
import { SiteHeader } from './components/Landing/site-header';
import { UseCasePage } from './components/UseCases/UseCasePage';
import * as seline from '@seline-analytics/web';
import { useEffect, useState } from 'react';
import { getApiDomain } from './auth_config';
import { LeadGeneration } from './components/EnterpriseHome/LeadGeneration';
import { getRedirectURL } from './constants';
import { VerifyLinkedIn } from './components/Auth/VerifyLinkedIn';
import { DevAgencyLanding } from './components/DevAgencyLanding';

SuperTokens.init(SuperTokensConfig);

export const SelineWrapper = ({ children }) => {
    const sessionContext = useSessionContext();
    const [hasCalledUpdateInfo, setHasCalledUpdateInfo] = useState(false);

    useEffect(() => {
        posthog.init('phc_8ayngDr5zFcIOTbulDvje0PNMDwRrDHvKW2Patg4hmJ', {
            api_host: 'https://app.posthog.com'
        });
        seline.init();
    }, []);

    useEffect(() => {
        if (sessionContext?.accessTokenPayload?.email) {
            posthog.identify(sessionContext.userId, {
                email: sessionContext.accessTokenPayload.email
            });
            seline.setUser({
                userId: sessionContext.accessTokenPayload.userId,
                email: sessionContext.accessTokenPayload.email,
                name: sessionContext.accessTokenPayload.email
            });

            if (!hasCalledUpdateInfo) {
                fetch(`${getApiDomain()}/updateinfo`, {
                    method: 'POST',
                    credentials: 'include'
                })
                    .then(response => response.json())
                    .then(data => {
                        setHasCalledUpdateInfo(true);
                    })
                    .catch(error => {
                        console.error('Error calling /updateinfo:', error);
                    });
            }
        }
    }, [
        sessionContext.accessTokenPayload,
        hasCalledUpdateInfo,
        sessionContext.userId
    ]);

    return <>{children}</>;
};

const EnterpriseWrapper = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    return !enterpriseOrganization ? (
        <Loading text="Organization" />
    ) : (
        <Outlet />
    );
};

function App() {
    return (
        <SuperTokensWrapper>
            <Toaster closeButton position="top-center" richColors />
            <ThirdpartyEmailPasswordComponentsOverrideProvider
                components={{
                    EmailPasswordSignInHeader_Override:
                        EmailPasswordHeadersOverride,
                    EmailPasswordSignUpHeader_Override:
                        EmailPasswordHeadersOverride
                }}
            >
                <ApolloProvider client={client}>
                    <SelineWrapper>
                        <Router>
                            <Routes>
                                {/* This shows the login UI on "/auth" route */}
                                {getSuperTokensRoutesForReactRouterDom(
                                    require('react-router-dom'),
                                    PreBuiltUIList
                                )}
                                <Route
                                    path="/auth/*"
                                    element={
                                        <div className="dark bg-black w-screen h-screen items-center justify-center flex">
                                            <UserAuthForm />
                                        </div>
                                    }
                                />
                                <Route
                                    path="/auth/reset-password"
                                    element={<Reset />}
                                />
                                <Route
                                    path="/auth/forgot-password"
                                    element={<Forgot />}
                                />

                                <Route
                                    path="/dev-agency"
                                    element={
                                        <EnterpriseOrganizationsProvider>
                                            <SiteHeader />
                                            <DevAgencyLanding />
                                            <Footer />
                                        </EnterpriseOrganizationsProvider>
                                    }
                                />

                                <Route
                                    path="/"
                                    element={
                                        <EnterpriseOrganizationsProvider>
                                            <EnterpriseOrganizationProvider>
                                                <SubscriptionPaymentProvider
                                                    isHomePage={true}
                                                >
                                                    <ProjectProvider>
                                                        <GraphProvider>
                                                            <LoadingProvider>
                                                                <SiteHeader />
                                                                <LandingPage />
                                                                <Footer />
                                                            </LoadingProvider>
                                                        </GraphProvider>
                                                    </ProjectProvider>
                                                </SubscriptionPaymentProvider>
                                            </EnterpriseOrganizationProvider>
                                        </EnterpriseOrganizationsProvider>
                                    }
                                >
                                    <Route path="demo/:projectId" />
                                    <Route path="how-it-works" />
                                    <Route path="leaderboard" />
                                </Route>

                                <Route
                                    path="use-cases/:useCaseId"
                                    element={
                                        <EnterpriseOrganizationsProvider>
                                            <EnterpriseOrganizationProvider>
                                                <SubscriptionPaymentProvider>
                                                    <ProjectProvider>
                                                        <SiteHeader />
                                                        <UseCasePage />
                                                        <Footer />
                                                    </ProjectProvider>
                                                </SubscriptionPaymentProvider>
                                            </EnterpriseOrganizationProvider>
                                        </EnterpriseOrganizationsProvider>
                                    }
                                />

                                <Route
                                    path="/enterprise"
                                    element={
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider>
                                                <ProjectProvider
                                                    isChatWidget={true}
                                                >
                                                    <LoadingProvider>
                                                        <EnterpriseLanding />
                                                    </LoadingProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    }
                                />

                                <Route
                                    path="/iframe/enterprise/chat/:apiKey"
                                    element={
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider>
                                                <ProjectProvider
                                                    isChatWidget={true}
                                                >
                                                    <LoadingProvider>
                                                        <Widget />
                                                    </LoadingProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    }
                                />

                                <Route
                                    path="/enterprise/chat/:apiKey"
                                    element={
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider>
                                                <ProjectProvider
                                                    isChatWidget={true}
                                                >
                                                    <LoadingProvider>
                                                        <ChatWidgetPage />
                                                    </LoadingProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    }
                                />

                                <Route
                                    path="/share/enterprise/:enterpriseOrganizationId/:projectId"
                                    element={
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isShare={true}
                                            >
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <div className="flex flex-col h-[100vh] w-full">
                                                                <ProjectDashboard />
                                                            </div>
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    }
                                />

                                <Route
                                    path="/share/:apiKey/:projectId"
                                    element={
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isShare={true}
                                            >
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <div className="flex flex-col h-[100vh] w-full">
                                                                <ProjectDashboard />
                                                            </div>
                                                            <Footer />
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    }
                                />

                                <Route
                                    path="/share/:projectId"
                                    element={
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isShare={true}
                                            >
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <ProjectDashboard />
                                                            <Footer />
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    }
                                />

                                <Route
                                    path="/enterprise/dashboard"
                                    element={
                                        <SessionAuth>
                                            <EnterpriseOrganizationsProvider>
                                                <EnterpriseOrganizationProvider>
                                                    <SubscriptionPaymentProvider
                                                        isEnterprise={true}
                                                    >
                                                        <ProjectsProvider>
                                                            <ProjectProvider>
                                                                <GraphProvider>
                                                                    <LoadingProvider>
                                                                        <EnterpriseHome />
                                                                    </LoadingProvider>
                                                                </GraphProvider>
                                                            </ProjectProvider>
                                                        </ProjectsProvider>
                                                    </SubscriptionPaymentProvider>
                                                </EnterpriseOrganizationProvider>
                                            </EnterpriseOrganizationsProvider>
                                        </SessionAuth>
                                    }
                                >
                                    <Route
                                        index
                                        path="new"
                                        element={<NewOrganization />}
                                    />

                                    <Route
                                        path=":enterpriseOrganizationId"
                                        element={<EnterpriseWrapper />}
                                    >
                                        <Route
                                            path="new-project/:apiKey"
                                            element={
                                                <div
                                                    className={`h-full flex flex-col justify-between items-center w-full`}
                                                >
                                                    <div className="flex justify-center items-center h-full w-full">
                                                        <section
                                                            className={`h-[590px] md:border-gray-200 md:border rounded-lg md:shadow-lg object-cover mx-auto max-w-[440px] bg-white flex-grow flex flex-col justify-between`}
                                                        >
                                                            <CreateNewProject
                                                                isEnterprise={
                                                                    true
                                                                }
                                                            />
                                                            <MainHeader />
                                                        </section>
                                                    </div>
                                                </div>
                                            }
                                        ></Route>

                                        <Route
                                            path="widgetManagement"
                                            element={<WidgetManagement />}
                                        />

                                        <Route
                                            path="knowledgeManagement"
                                            element={<KnowledgeManagement />}
                                        />

                                        <Route path="leadGeneration">
                                            <Route
                                                index
                                                element={<LeadGeneration />}
                                            />

                                            <Route
                                                path=":projectId"
                                                element={
                                                    <ProjectDashboard
                                                        isLeadGen={true}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="clientProjects">
                                            <Route
                                                index
                                                element={<ClientProjects />}
                                            />

                                            <Route
                                                path=":projectId"
                                                element={<ProjectDashboard />}
                                            />
                                        </Route>

                                        <Route
                                            path="enterpriseUsers"
                                            element={<EnterpriseUsers />}
                                        />
                                    </Route>
                                </Route>

                                <Route
                                    path="/projects"
                                    element={
                                        <SessionAuth>
                                            <EnterpriseOrganizationProvider>
                                                <SubscriptionPaymentProvider
                                                    isSolo={true}
                                                >
                                                    <ProjectsProvider>
                                                        <ProjectProvider>
                                                            <GraphProvider>
                                                                <LoadingProvider>
                                                                    <Home />
                                                                </LoadingProvider>
                                                            </GraphProvider>
                                                        </ProjectProvider>
                                                    </ProjectsProvider>
                                                </SubscriptionPaymentProvider>
                                            </EnterpriseOrganizationProvider>
                                        </SessionAuth>
                                    }
                                >
                                    <Route
                                        index
                                        path="new"
                                        element={<NewProject />}
                                    />

                                    <Route
                                        path=":projectId"
                                        element={<ProjectDashboard />}
                                    />
                                </Route>
                                <Route
                                    path="meetingScheduled"
                                    element={<MeetingScheduledRedirect />}
                                />
                                <Route
                                    path="verifyLinkedIn"
                                    element={
                                        <SessionAuth>
                                            <VerifyLinkedIn />
                                        </SessionAuth>
                                    }
                                />
                            </Routes>
                        </Router>
                    </SelineWrapper>
                </ApolloProvider>
            </ThirdpartyEmailPasswordComponentsOverrideProvider>
        </SuperTokensWrapper>
    );
}

function MeetingScheduledRedirect() {
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    window.location.href = '/';
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="flex flex-col items-center justify-center h-screen px-4">
            <img
                src="/predevlogosquareblack.png"
                alt="Meeting scheduled"
                className="w-[100px] h-[100px]"
            />
            <h1 className="text-2xl font-bold">Meeting scheduled!</h1>
            <p className="text-lg">
                Redirecting to pre.dev in {countdown} seconds...
            </p>
        </div>
    );
}

export default App;
