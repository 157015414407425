import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    selectSkillLevel,
    setSkillLevel,
    selectSow
} from '../../../../redux/reducers/generic/sow';
import { Icon } from '../TipTap/components/ui/Icon';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import Modal from '../../../Components/Modal';
import { saveSkillLevel } from '../../../../redux/actions/solo/saveSkillLevel';
import { useProject } from '../../../../context-providers/Project';

export default function SkillLevel() {
    const dispatch = useDispatch();
    const skillLevel = useSelector(selectSkillLevel);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const sow = useSelector(selectSow);
    const { isSubbed, isEnterprise } = useSubscriptionPayment();
    const { projectId, project } = useProject();

    const [options, setOptions] = useState([
        {
            name: 'Junior Devs',
            value: 'junior',
            icon: 'GraduationCap',
            price: 50
        },
        {
            name: 'Intermediate Devs',
            value: 'intermediate',
            icon: 'Code',
            price: 100
        },
        {
            name: 'Senior Devs',
            value: 'senior',
            icon: 'Briefcase',
            price: 200
        }
    ]);

    useEffect(() => {
        const prices = project.prices;
        if (prices) {
            setOptions([
                {
                    name: 'Junior Devs',
                    value: 'junior',
                    icon: 'GraduationCap',
                    price: prices[0]
                },
                {
                    name: 'Intermediate Devs',
                    value: 'intermediate',
                    icon: 'Code',
                    price: prices[1]
                },
                {
                    name: 'Senior Devs',
                    value: 'senior',
                    icon: 'Briefcase',
                    price: prices[2]
                }
            ]);
        }
    }, [projectId]);

    const handleSkillLevelChange = value => {
        dispatch(setSkillLevel(value));
        setIsModalOpen(false);
    };

    const handleChangePrice = (value, index) => {
        const newOptions = [...options];
        newOptions[index].price = parseInt(value);
        setOptions(newOptions);
    };

    const handleClose = e => {
        setIsModalOpen(false);
        const prices = options.map(option => parseInt(option.price));
        dispatch(saveSkillLevel({ projectId, prices }));
        dispatch(setSkillLevel(skillLevel));
    };

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const currentOption = options.find(option => option.value === skillLevel);

    const modalContent = (
        <div className="py-4">
            <h3 className="text-lg font-medium leading-6 text-white mb-4">
                Select Skill Level
            </h3>
            <div className="space-y-4">
                {options.map((option, index) => (
                    <button
                        key={option.value}
                        onClick={() => handleSkillLevelChange(option.value)}
                        className="flex items-center gap-x-4 justify-between px-4 py-3 text-sm text-white hover:bg-gray-800 w-full text-left rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 border border-gray-700"
                    >
                        <div className="flex items-center">
                            <Icon
                                name={option.icon}
                                className="mr-3 h-6 w-6 text-indigo-400"
                            />
                            <span className="font-medium">{option.name}</span>
                        </div>
                        {isEnterprise ? (
                            <>
                                $
                                <input
                                    type="text"
                                    defaultValue={`${option.price}`}
                                    className="max-w-[60px] text-indigo-400 font-semibold bg-gray-900 border-none focus:outline-none"
                                    onClick={e => e.stopPropagation()}
                                    onChange={e =>
                                        handleChangePrice(e.target.value, index)
                                    }
                                />{' '}
                                / hr
                            </>
                        ) : (
                            <span className="text-indigo-400 font-semibold">
                                ~${option.price}/hr
                            </span>
                        )}
                    </button>
                ))}
            </div>
            <div className="mt-4 pt-4 text-xs text-gray-400 border-t border-gray-700">
                Hours will be estimated for the selected role
            </div>
        </div>
    );

    return (
        sow?.completed && (
            <>
                <button
                    onClick={toggleModal}
                    className="flex items-center px-3 py-2 text-sm font-medium text-white bg-black border border-gray-700 rounded-md hover:bg-gray-900 focus:outline-none"
                >
                    <Icon
                        name={currentOption ? currentOption.icon : 'User'}
                        className="mr-2 h-5 w-5 text-white"
                    />
                    <span className="inline">
                        {currentOption
                            ? currentOption.name
                            : 'Select Skill Level'}
                    </span>
                    <Icon
                        name="ChevronDown"
                        className="ml-2 h-5 w-5 text-white"
                    />
                </button>
                <Modal
                    open={isModalOpen}
                    onClose={handleClose}
                    maxWidth="max-w-md"
                    bgColor="bg-black"
                >
                    {modalContent}
                </Modal>
            </>
        )
    );
}
