import gql from 'graphql-tag';
import {
    existingProjectContext,
    graph,
    leaderboardEntry,
    message,
    project,
    sow,
    thumbsRating,
    userProfile
} from '../helper';

export const ADD_SUBTASK = gql`
    mutation AddSubTask(
        $storyId: ID!
        $projectId: ID!
        $description: String
        $complexityEstimate: String
        $hours: Int
        $roles: [RoleInput]
        $index: Int
    ) {
        addSubTask(
            storyId: $storyId
            projectId: $projectId
            description: $description
            complexityEstimate: $complexityEstimate
            hours: $hours
            roles: $roles
            index: $index
        )
    }
`;
export const EDIT_SUBTASK = gql`
    mutation EditSubTask(
        $subTaskId: ID!
        $description: String
        $complexityEstimate: String
        $hours: Int
        $roles: [RoleInput]
        $index: Int
    ) {
        editSubTask(
            subTaskId: $subTaskId
            description: $description
            complexityEstimate: $complexityEstimate
            hours: $hours
            roles: $roles
            index: $index
        )
    }
`;

export const SAVE_PRICES = gql`
    mutation SavePrices($projectId: ID!, $prices: [Int]!) {
        savePrices(projectId: $projectId, prices: $prices)
    }
`;

export const DELETE_SUBTASK = gql`
    mutation DeleteSubTask($subTaskId: ID!) {
        deleteSubTask(subTaskId: $subTaskId)
    }
`;

export const EDIT_USER_STORY = gql`
    mutation EditUserStory(
        $storyId: ID!
        $projectId: ID!
        $description: String
        $priority: String
        $acceptanceCriteria: String
    ) {
        editUserStory(
            projectId: $projectId
            storyId: $storyId
            description: $description
            priority: $priority
            acceptanceCriteria: $acceptanceCriteria
        )
    }
`;
export const DELETE_USER_STORY = gql`
    mutation DeleteUserStory($projectId: ID!, $storyId: ID!) {
        deleteUserStory(projectId: $projectId, storyId: $storyId)
    }
`;
export const ADD_USER_STORY = gql`
    mutation AddUserStory(
        $nodeId: ID
        $projectId: ID
        $description: String
        $priority: String
        $acceptanceCriteria: String
    ) {
        addUserStory(
            nodeId: $nodeId
            projectId: $projectId
            description: $description
            priority: $priority
            acceptanceCriteria: $acceptanceCriteria
        )
    }
`;

export const DELETE_NODE = gql`
    mutation DeleteNode($nodeId: ID!, $graphId: ID!, $projectId: ID!) {
        deleteNode(nodeId: $nodeId, graphId: $graphId, projectId: $projectId)
    }
`;
export const EDIT_NODE = gql`
    mutation EditNode(
        $nodeId: ID!
        $graphId: ID!
        $projectId: ID!
        $label: String
        $description: String
    ) {
        editNode(
            nodeId: $nodeId
            graphId: $graphId
            projectId: $projectId
            label: $label
            description: $description
        )
    }
`;

export const CREATE_PROJECT = gql`
    mutation CreateProject(
        $name: String!
        $type: String!
        $fileKeyS3: String
        $hasExistingContext: Boolean
        $matchWithDev: Boolean
        $budget: String
        $urgency: Int
        $phoneNumber: String
    ) {
        createProject(
            name: $name
            type: $type
            fileKeyS3: $fileKeyS3
            hasExistingContext: $hasExistingContext
            matchWithDev: $matchWithDev
            budget: $budget
            urgency: $urgency
            phoneNumber: $phoneNumber
        ) {
            ${project}
        }
    }
`;

export const SAVE_SOW = gql`
    mutation SaveSow(
        $projectId: ID!
        $type: String!
        $version: Int!
        $content: String!
    ) {
        saveSow(
            projectId: $projectId
            type: $type
            version: $version
            content: $content
        ) {
            ${sow}
        }
    }
`;

export const SAVE_UIVIEW = gql`
    mutation SaveUIView(
        $projectId: ID!
        $nodeId: String!
        $version: Int!
        $codefiles: [CodeFileInput]!
    ) {
        saveUIView(
            projectId: $projectId
            nodeId: $nodeId
            version: $version
            codefiles: $codefiles
        )
    }
`;

export const UPDATE_SCREENSHOT_UIVIEW = gql`
    mutation SaveSow(
        $projectId: ID!
        $type: String!
        $version: Int!
        $content: String!
    ) {
        saveSow(
            projectId: $projectId
            type: $type
            version: $version
            content: $content
        )
    }
`;

export const CORRECT_UI_VIEW_ERRORS = gql`
    mutation correctUIViewErrors(
        $projectId: ID!
        $nodeId: ID!
        $version: Int!
        $errors: [ErrorInput]
    ) {
        correctUIViewErrors(
            projectId: $projectId
            nodeId: $nodeId
            version: $version
            errors: $errors
        ) {
            _id
        }
    }
`;

export const SKIP_QUESTIONS = gql`
    mutation SkipQuestions($projectId: ID!) {
        skipQuestions(projectId: $projectId) {
            _id
        }
    }
`;

export const GET_SIGNED_UPLOAD_URL = gql`
    mutation GetSignedUploadURL($filename: String!, $isLogo: Boolean) {
        getSignedUploadURL(filename: $filename, isLogo: $isLogo)
    }
`;

export const GET_SIGNED_DOWNLOAD_URL = gql`
    mutation GetSignedDownloadURL($filename: String!) {
        getSignedDownloadURL(filename: $filename)
    }
`;

export const INITIATE_FOLLOW_UP_QUESTION = gql`
	mutation initiateFollowUpQuestionStream(
		$answer: MessageInput!
		$projectId: ID!
	) {
		initiateFollowUpQuestionStream(answer: $answer, projectId: $projectId) {
			${message}
		}
	}
`;

export const INITIATE_FOLLOW_UP_QUESTION_UIVIEW = gql`
    mutation initiateFollowUpQuestionUIViewStream(
        $answer: MessageInput!
        $projectId: ID!
        $nodeId: ID!
    ) {
        initiateFollowUpQuestionUIViewStream(answer: $answer, projectId: $projectId, nodeId: $nodeId) {
            ${message}
        }
    }
`;

export const INITIATE_SUGGESTION = gql`
    mutation initiateSuggestionStream($projectId: ID!, $randId: ID!) {
        initiateSuggestionStream(projectId: $projectId, randId: $randId) {
            suggestion
            completed
        }
    }
`;

export const REQUEST_MEETING = gql`
    mutation requestMeeting($projectId: ID!) {
        requestMeeting(projectId: $projectId) {
            _id
            name
            created
            meetingRequested
        }
    }
`;

export const DELETE_PROJECT = gql`
    mutation deleteProject($projectId: ID!) {
        deleteProject(projectId: $projectId)
    }
`;

export const EDIT_PROJECT_NAME = gql`
    mutation editProjectName($projectId: ID!, $name: String!) {
        editProjectName(projectId: $projectId, name: $name)
    }
`;

export const MODIFY_SOW = gql`
    mutation modifySow(
        $projectId: ID!
        $type: String!
        $version: Int!
        $comment: String
        $increaseDetail: Boolean
        $decreaseDetail: Boolean
    ) {
        modifySow(
            projectId: $projectId
            type: $type
            version: $version
            comment: $comment
            increaseDetail: $increaseDetail
            decreaseDetail: $decreaseDetail
        ) {
            content
            version
        }
    }
`;

export const REMOVE_TECH_STACK_ITEM_FROM_PROJECT = gql`
    mutation removeTechStackItemFromProject($projectId: ID!, $index: Int!) {
        removeTechStackItemFromProject(projectId: $projectId, index: $index)
    }
`;

export const ADD_TECH_STACK_ITEM_TO_PROJECT = gql`
    mutation addTechStackItemToProject($projectId: ID!, $name: String!) {
        addTechStackItemToProject(projectId: $projectId, name: $name)
    }
`;

export const ADD_NODES_EDGES_TO_PROJECT_GRAPH = gql`
    mutation addNodesEdgesToProjectGraph(
        $projectId: ID!
        $side: String!
        $nodesEdges: InputGraph
    ) {
        addNodesEdgesToProjectGraph(
            projectId: $projectId
            side: $side
            nodesEdges: $nodesEdges
        ) {
            ${graph}
        }
    }
`;

export const ADD_EXISTING_PROJECT_CONTEXT = gql`
    mutation addExistingProjectContext(
        $fileKeyS3: String
        $text: String
        $image_url: String
        $type: String!
        $projectId: ID!
    ) {
        addExistingProjectContext(
            fileKeyS3: $fileKeyS3
            text: $text
            image_url: $image_url
            type: $type
            projectId: $projectId
        ) {
            ${existingProjectContext}
        }
    }
`;

export const REMOVE_EXISTING_PROJECT_CONTEXT = gql`
    mutation removeExistingProjectContext($existingProjectContextId: ID!) {
        removeExistingProjectContext(
            existingProjectContextId: $existingProjectContextId
        )
    }
`;

export const GENERATE_SOW_CUSTOM = gql`
    mutation generateSowCustom($projectId: ID!, $type: String!) {
        generateSowCustom(projectId: $projectId, type: $type) {
            ${sow}
        }
    }
`;

export const UPDATE_THUMBS_RATING = gql`
    mutation updateThumbsRating(
        $id: String!
        $rating: String!
        $metadata: [String]!
    ) {
        updateThumbsRating(id: $id, rating: $rating, metadata: $metadata) {
            ${thumbsRating}
        }
    }
`;

export const UPGRADE_TO_SOLO_PREMIUM = gql`
    mutation upgradeToSoloPremium {
        upgradeToSoloPremium
    }
`;

export const SUBMIT_PROJECT_TO_LEADERBOARD = gql`
    mutation submitProjectToLeaderboard(
        $projectId: ID!
        $description: String!
        $name: String!
    ) {
        submitProjectToLeaderboard(
            projectId: $projectId
            description: $description
            name: $name
        ) {
            ${leaderboardEntry}
        }
    }
`;

export const VOTE_ON_LEADERBOARD_ENTRY = gql`
    mutation voteOnLeaderboardEntry($leaderboardEntryId: ID!, $remove: Boolean) {
        voteOnLeaderboardEntry(leaderboardEntryId: $leaderboardEntryId, remove: $remove) {
            ${leaderboardEntry}
        }
    }
`;

export const VOTE_ON_COMMENT = gql`
    mutation voteOnComment($commentId: ID!, $remove: Boolean) {
        voteOnComment(commentId: $commentId, remove: $remove) {
            ${leaderboardEntry}
        }
    }
`;

export const COMMENT_ON_LEADERBOARD_ENTRY = gql`
    mutation commentOnLeaderboardEntry(
        $leaderboardEntryId: ID!
        $parentCommentId: ID
        $content: String!
    ) {
        commentOnLeaderboardEntry(
            leaderboardEntryId: $leaderboardEntryId
            parentCommentId: $parentCommentId
            content: $content
        ) {
            ${leaderboardEntry}
        }
    }
`;

export const DELETE_COMMENT = gql`
    mutation deleteComment($commentId: ID!) {
        deleteComment(commentId: $commentId) {
            ${leaderboardEntry}
        }
    }
`;

export const REMOVE_PROJECT_FROM_LEADERBOARD = gql`
    mutation removeProjectFromLeaderboard($projectId: ID!) {
        removeProjectFromLeaderboard(projectId: $projectId)
    }
`;

export const REPORT_LEADERBOARD_ENTRY = gql`
    mutation reportLeaderboardEntry($leaderboardEntryId: ID!) {
        reportLeaderboardEntry(leaderboardEntryId: $leaderboardEntryId)
    }
`;

export const MIGRATE_PROJECT = gql`
    mutation migrateProject($projectId: ID!, $fileKeyS3: String!) {
        migrateProject(projectId: $projectId, fileKeyS3: $fileKeyS3) {
            ${project}
        }
    }
`;

export const MATCH_WITH_DEV = gql`
    mutation matchWithDev($projectId: ID!, $value: Boolean) {
        matchWithDev(projectId: $projectId, value: $value)
    }
`;

export const SET_PROJECT_BUDGET = gql`
    mutation setProjectBudget($projectId: ID!, $budget: String!) {
        setProjectBudget(projectId: $projectId, budget: $budget)
    }
`;

export const SET_PROJECT_SCHEDULING_LINK = gql`
    mutation setProjectSchedulingLink(
        $projectId: ID!
        $schedulingLink: String!
    ) {
        setProjectSchedulingLink(
            projectId: $projectId
            schedulingLink: $schedulingLink
        ) {
            ${project}
        }
    }
`;

export const SET_PROJECT_URGENCY = gql`
    mutation setProjectUrgency($projectId: ID!, $urgency: Int!) {
        setProjectUrgency(projectId: $projectId, urgency: $urgency) {
            ${project}
        }
    }
`;

export const UPDATE_USER_METADATA = gql`
    mutation updateUserMetadata($fields: [KeyValuePair]!) {
        updateUserMetadata(fields: $fields) {
            ${userProfile}
        }
    }
`;
