import { useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import type { PDFDocumentProxy } from 'pdfjs-dist';
let url = new URL('/pdf.worker.mjs', window.location.href).toString();

pdfjs.GlobalWorkerOptions.workerSrc = url;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/'
};

const resizeObserverOptions = {};

type PDFFile = string | File | null;

export function PDFViewer() {
    const [file, setFile] = useState<PDFFile>('./pre.dev-dev-agency.pdf');
    const [numPages, setNumPages] = useState<number>();
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>();

    const onResize = useCallback<ResizeObserverCallback>(entries => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    function onDocumentLoadSuccess({
        numPages: nextNumPages
    }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }

    return (
        <div className="w-full h-full overflow-y-scroll mx-auto">
            <div className="Example__container__document" ref={setContainerRef}>
                <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={options}
                >
                    {Array.from(new Array(numPages), (_el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={containerWidth}
                        />
                    ))}
                </Document>
            </div>
        </div>
    );
}
