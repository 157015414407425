import {
    formatBoldText,
    formatBulletList,
    formatHeading,
    formatItalicText,
    formatListItem,
    formatParagraph,
    formatText
} from './helper';

const formatSubTask = (subTask, hide_hours, previewMode) => {
    return formatListItem([
        formatParagraph([
            formatText(`${subTask?.description}`, previewMode),
            formatText(
                ` - (${subTask?.complexityEstimate})`,
                previewMode,
                true
            ),
            ...(!hide_hours
                ? [formatText(` (${subTask?.hours} hours)`, previewMode, true)]
                : []),
            ...(subTask?.roles?.map(role =>
                formatText(`[${role?.shortHand}]`, previewMode, true)
            ) || [])
        ])
    ]);
};

const formatStory = (story, hide_hours, previewMode, quoteMode) => {
    const storyTotalHours =
        story.hours ||
        (story.subTasks
            ? story.subTasks.reduce(
                  (total, subTask) => total + (subTask.hours || 0),
                  0
              )
            : 0);

    let subTasksContent = story?.subTasks?.map(subTask =>
        formatSubTask(subTask, hide_hours, previewMode)
    );
    if (quoteMode) subTasksContent = '';

    return formatListItem([
        formatParagraph(
            [
                story?.title
                    ? formatBoldText(`${story?.title}: `, false)
                    : null,
                story?.description !== story?.title
                    ? formatItalicText(`${story?.description}`, false)
                    : null,
                !hide_hours
                    ? formatBoldText(
                          `(${storyTotalHours} hours)`,
                          previewMode,
                          true
                      )
                    : null,
                ...(story?.acceptance_criteria
                    ? story.acceptance_criteria
                          .split(/\d+\./)
                          .filter(Boolean)
                          .map(criteria =>
                              formatText(`\n - ${criteria.trim()}`, false)
                          )
                    : [])
            ].filter(Boolean)
        ),
        formatBulletList(subTasksContent || [])
    ]);
};

const formatMilestone = (
    milestone,
    index,
    hide_hours,
    previewMode,
    quoteMode
) => {
    try {
        const epicTotalHours =
            milestone.hours ||
            milestone.stories.reduce((totalEpic, story) => {
                const storyHours = story.subTasks
                    ? story.subTasks.reduce(
                          (totalStory, subTask) =>
                              totalStory + (subTask.hours || 0),
                          0
                      )
                    : story.hours || 0;
                return totalEpic + storyHours;
            }, 0);

        const storiesContent = milestone?.stories?.map(story =>
            formatStory(story, hide_hours, previewMode, quoteMode)
        );

        return [
            formatHeading(
                3,
                [
                    formatBoldText(`Milestone ${index + 1}:`, false),
                    formatBoldText(` ${milestone?.description}`, false)
                ].filter(Boolean)
            ),
            ...(!hide_hours
                ? [
                      formatParagraph([
                          formatItalicText(
                              `Estimated ${epicTotalHours} hours`,
                              previewMode,
                              true
                          )
                      ])
                  ]
                : []),
            formatBulletList(storiesContent || [])
        ];
    } catch (error) {
        console.log(error);
        return [
            formatHeading(3, [formatText(`Milestone ${index + 1}`, false)]),
            formatParagraph([
                formatText('Error loading milestone details.', false)
            ])
        ];
    }
};

const generateRolesList = milestones => {
    if (!milestones) return [];

    try {
        const roles = milestones
            ?.flatMap(epic => epic?.stories)
            ?.flatMap(story => story?.subTasks)
            ?.flatMap(subTask => subTask?.roles || [])
            ?.reduce((acc, role) => {
                if (!acc.some(item => item?.name === role?.name)) {
                    acc.push(role);
                }
                return acc;
            }, [])
            ?.filter(role => role?.name && role?.shortHand);

        return roles;
    } catch (error) {
        return [];
    }
};

const formatRoles = (roles, previewMode) => {
    return roles.length
        ? [
              {
                  type: 'bulletList',
                  content: roles.map(role => ({
                      type: 'listItem',
                      content: [
                          formatParagraph([
                              ...(role?.name
                                  ? [formatBoldText(role.name, false)]
                                  : []),
                              ...(role?.shortHand
                                  ? [formatText(` (${role.shortHand})`, false)]
                                  : [])
                          ])
                      ]
                  }))
              }
          ]
        : [
              {
                  type: 'paragraph',
                  attrs: { class: null, textAlign: 'left' },
                  content: [formatText('Loading...', false)]
              }
          ];
};

export const formatMilestones = (sow, hide_hours, previewMode, quoteMode) => {
    if (!sow.completed) hide_hours = true;
    let formattedContent = [];
    try {
        let heading = formatHeading(2, [formatText('Project Timeline', false)]);
        let description = formatParagraph([
            formatText(
                'Tasks are categorized by complexity to guide time estimations: straightforward (S), moderate (M), challenging (C).',
                false
            )
        ]);
        let rolesHeading = formatParagraph([formatBoldText('Roles:', false)]);
        let roles = generateRolesList(sow?.milestones);
        let formattedRoles = formatRoles(roles, previewMode);
        let milestones =
            sow?.milestones
                ?.map((milestone, index) =>
                    formatMilestone(
                        milestone,
                        index,
                        hide_hours,
                        previewMode,
                        quoteMode
                    )
                )
                .flat() || [];

        let previewText = [
            formatHeading(2, [
                formatText('🚀 Unlock Full Project Timeline', false)
            ]),
            formatParagraph([
                formatText('⭐️ ', false),
                formatText('Subscribe to pre.dev now', false, true),
                formatText(' to access the complete Project Timeline!', false)
            ])
        ];
        formattedContent = [
            heading,
            description,
            rolesHeading,
            ...formattedRoles,
            ...(previewMode ? previewText : []),
            ...milestones
        ];

        if (!hide_hours) {
            const totalHours =
                sow?.milestones?.reduce(
                    (total, milestone) => total + (milestone.hours || 0),
                    0
                ) || 0;
            const totalHoursContent = [
                formatHeading(3, [
                    formatBoldText(
                        'Total Hours: ' + totalHours.toString(),
                        false
                    )
                ])
            ];
            formattedContent.push(...totalHoursContent);
        }
    } catch (error) {
        formattedContent = [
            formatHeading(2, [formatText('Project Timeline', false)]),
            formatParagraph([formatText('Loading...', false)])
        ];
    }
    return formattedContent;
};
