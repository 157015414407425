import { toast } from 'sonner';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { SendIntroductionEmail } from './SendIntroductionEmail';
import { RevealEmail } from './RevealEmail';
import { Indicators } from './Indicators';

export const UserEmail = ({ project, showShareLink }) => {
    const email = project.userEmail;
    const leadGenMeetingTriggered = project.leadGenMeetingTriggered;
    const isRevealed = project.leadGenEmailRevealed;
    const projectId = project._id;

    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText(email).then(() => {
            toast('User email copied to clipboard!', {
                type: 'success'
            });
        });
    };

    return (
        <div className="flex items-center space-x-2">
            {email ? (
                <div className="flex items-center w-full justify-between gap-x-2">
                    <div className="flex items-center gap-x-2">
                        <div
                            className="flex gap-x-2 cursor-pointer"
                            onClick={copyEmailToClipboard}
                        >
                            <span>{email}</span>
                            <ClipboardIcon
                                className={`h-5 transition-opacity duration-300 ${
                                    !showShareLink ? 'opacity-0' : ''
                                }`}
                            />
                        </div>
                    </div>
                    {leadGenMeetingTriggered ? (
                        <span className="px-2 py-1 text-xs font-medium text-green-700 bg-green-100 rounded-full">
                            Introduction Sent
                        </span>
                    ) : (
                        <SendIntroductionEmail projectId={projectId} />
                    )}
                </div>
            ) : (
                <div className="relative">
                    {showShareLink && !isRevealed ? (
                        <RevealEmail projectId={projectId} />
                    ) : (
                        <div className="px-3 py-1.5 text-sm font-medium text-gray-600 bg-gray-200 rounded-md animate-pulse">
                            ••••••@•••••.com
                        </div>
                    )}
                </div>
            )}
            <Indicators project={project} />
        </div>
    );
};
