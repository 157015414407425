import { useLocation } from 'react-router-dom';
import { ProjectArchitecture } from '../ArchitectureGraph/index';
import { NodeDetailModal } from '../ArchitectureGraph/UIBuilder';
import { useSelector } from 'react-redux';
import { selectShowNodeDetailModal } from '../../../redux/reducers/generic/projectArchitecture';
export const CodeGen = () => {
    const open = useSelector(selectShowNodeDetailModal);
    return (
        <>
            {!open ? (
                <div className="flex flex-col">
                    <h4 className="p-4 text-center -mt-4 text-xl sm:text-2xl font-bold tracking-tight text-black dark:text-purple-600">
                        Select a node to generate code
                        <div className="flex justify-center mt-4">
                            <svg
                                className="animate-bounce w-6 h-6 text-black dark:text-purple-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </div>
                    </h4>
                    <div className="h-[calc(100vh-220px)] overflow-y-auto">
                        <ProjectArchitecture noBudget={true} />
                    </div>
                </div>
            ) : (
                <NodeDetailModal />
            )}
        </>
    );
};
