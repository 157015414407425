import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { useProject } from '../../../context-providers/Project';
import { setShowRequestModal } from '../../../redux/reducers/generic/project';
import { useDispatch } from 'react-redux';
import trackEvent from '../../../helpers/trackEvent';

export const MatchWithDev = ({
    matchWithDev,
    setMatchWithDev,
    matchLoading,
    smallVersion
}) => {
    const { project, projectId } = useProject();
    const { accessTokenPayload } = useSessionContext();
    const { isHomePage, isSolo } = useSubscriptionPayment();
    const userEmail = accessTokenPayload?.email;
    const canEdit = project?.userEmail === userEmail || isHomePage || isSolo;
    const dispatch = useDispatch();

    async function handleMatchChange(checked) {
        let lastCall = 0;
        const THROTTLE_MS = 500;

        const now = Date.now();
        if (now - lastCall < THROTTLE_MS) {
            return;
        }
        lastCall = now;
        if (checked) {
            await setMatchWithDev(checked);
            dispatch(setShowRequestModal(true));
            trackEvent('matchWithDevEnabled', {
                userEmail: project?.userEmail || accessTokenPayload?.email,
                userId: accessTokenPayload?.userId,
                conversionId: projectId,
                projectId
            });
        } else {
            setMatchWithDev(checked);
        }
    }

    if (smallVersion) {
        return (
            <div
                className="flex items-center gap-x-2"
                onClick={() => {
                    if (canEdit) {
                        handleMatchChange(!matchWithDev);
                    }
                }}
            >
                <input
                    type="checkbox"
                    id="matchWithDev"
                    checked={matchWithDev}
                    className={`mr-2 ${
                        matchLoading || !canEdit
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer'
                    }`}
                    disabled={!canEdit}
                />
                <label htmlFor="matchWithDev" className="text-sm text-gray-200">
                    Match Me With a Dev
                </label>
                {matchLoading && (
                    <div className="ml-2 animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                )}
            </div>
        );
    }

    return (
        <div
            className="flex flex-col items-start space-y-2 bg-gradient-to-br from-gray-900 to-black rounded-lg shadow-lg border-2 border-gray-700 p-4"
            onClick={() => {
                if (canEdit) {
                    handleMatchChange(!matchWithDev);
                }
            }}
        >
            <div className="flex items-center">
                <input
                    type="checkbox"
                    id="matchWithDev"
                    checked={matchWithDev}
                    className={`mr-2 ${
                        matchLoading || !canEdit
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer'
                    }`}
                    disabled={!canEdit}
                />
                <label
                    htmlFor="matchWithDev"
                    className={`text-sm text-gray-200 ${
                        matchLoading || !canEdit
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }`}
                >
                    Match Me With a Dev
                </label>
                {matchLoading && (
                    <div className="ml-2 animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                )}
            </div>
            <p className="text-xs text-gray-300">
                Allow dev agencies to contact me about this project
            </p>
        </div>
    );
};
