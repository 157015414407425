import { client } from '../../../api/client';
import { DELETE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';

export const deleteOrganization =
    enterpriseOrganizationId => async (dispatch, getState) => {
        try {
            let { data } = await client.mutate({
                mutation: DELETE_ORGANIZATION,
                variables: { enterpriseOrganizationId }
            });

            console.log(data);

            if (data.deleteOrganization) {
                window.location.href = '/enterprise/dashboard';
            }
            return null;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
            return error.message;
        }
    };
