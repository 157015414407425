import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { EmptyProjects } from './EmptyProjects';
import { useProjects } from '../../../context-providers/Projects';
import { ProjectsList } from './ProjectsList';
import { Title } from './title';
import LoadMoreButton from '../../Home/Sidebars/LoadMoreButton';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    paginateProjects,
    selectProjectsSkip,
    selectProjectsTotal,
    selectSearchLoading
} from '../../../redux/reducers/generic/projects';
import { projectsLimit } from '../../../constants';
import { SearchProjects } from './Search';
import { FilterBox } from './Filter';
import { Credits } from './credits';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { toggleSubscribeModal } from '../../../redux/reducers/generic/billing';
import { setIsLeadGen } from '../../../redux/reducers/generic/project';
import { ContactDetails } from './ContactDetails';
import { setAddOn } from '../../../redux/reducers/enterprise/enterpriseOrganization';
import { LeadGenExplainer } from './Explainer';

export const LeadGeneration = ({}) => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const subscriptionStatus = enterpriseOrganization?.subscriptionStatus;
    const searchLoading = useSelector(selectSearchLoading);
    const { projects } = useProjects();
    if (!projects) projects = [];
    const dispatch = useDispatch();
    const divRef = useRef(null);
    const { isSubbed } = useSubscriptionPayment();

    const totalProjects = useSelector(selectProjectsTotal);
    const skip = useSelector(selectProjectsSkip);

    useEffect(() => {
        dispatch(setIsLeadGen(true));
    }, [dispatch]);

    useEffect(() => {
        const div = divRef.current;

        const handleScroll = () => {
            let showShowMoreButton = totalProjects > skip + projectsLimit;

            const isBottom =
                div?.scrollTop + div?.clientHeight >= div?.scrollHeight;
            console.log({ isBottom });
            console.log({ totalProjects, skip, projectsLimit });
            if (isBottom && showShowMoreButton) {
                console.log('paginate');
                dispatch(paginateProjects());
            }
        };

        div.addEventListener('scroll', handleScroll);

        return () => {
            div.removeEventListener('scroll', handleScroll);
        };
    }, [divRef, totalProjects, skip, projectsLimit]);

    return (
        <>
            <div className="h-full" ref={divRef} style={{ overflow: 'scroll' }}>
                {/* Main column */}
                <div className="flex flex-col">
                    <main className="flex-1">
                        {/* Page title & actions */}
                        <div className="sm:flex sm:justify-between border-b border-gray-200 px-4 mb-4 sticky top-0 bg-white z-5">
                            <Title />
                            <Credits />
                        </div>
                        <SalesHeader addOn="LeadGenPlus" />

                        <div className="flex md:flex-row flex-col items-center justify-center px-4 sm:px-2 lg:px-8 gap-x-4 gap-y-2">
                            {/* <SearchProjects /> */}
                            <LeadGenExplainer />
                            <ContactDetails />
                        </div>
                        <div className="flex mt-4 flex-col items-center justify-center px-4 sm:px-2 lg:px-8 gap-x-4 gap-y-2">
                            <FilterBox />
                        </div>
                        {projects && projects.length > 0 ? (
                            <div className="h-full">
                                <ProjectsList />
                                <div id="page-end" />

                                {!searchLoading && (
                                    <div className="flex justify-center">
                                        <LoadMoreButton />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <EmptyProjects />
                        )}
                    </main>
                </div>
            </div>
        </>
    );
};
export const SalesHeader = ({
    text = 'Unlock Premium Lead Generation',
    addOn
}) => {
    const { isSubbed } = useSubscriptionPayment();
    const dispatch = useDispatch();

    if (isSubbed) return null;

    return (
        <div className="flex flex-col items-center mb-4">
            <div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg p-4 shadow-lg">
                <h3 className="text-lg font-bold text-white mb-2">{text}</h3>
                <hr />
                <button
                    onClick={() => {
                        dispatch(toggleSubscribeModal());
                        dispatch(setAddOn(addOn));
                    }}
                    className="w-full inline-flex items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-blue-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white transition-all duration-200"
                >
                    Upgrade to Enterprise Premium
                </button>
            </div>
        </div>
    );
};
