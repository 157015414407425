import { useState, useEffect } from 'react';

export const ScrollIndicator = ({ projectId }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        setIsVisible(position <= 1000);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!isVisible || projectId) return null;

    return (
        <div
            className={`z-10 animate-fade-in absolute ${
                projectId ? 'top-[90%] xl:top-[90%]' : 'lg:top-[90%] top-[90%]'
            } left-[10px] transform flex flex-col items-center`}
        >
            <div className="flex flex-col justify-center items-center gap-y-2">
                <a href="#hero" className="cursor-pointer text-white mx-2">
                    {' '}
                    learn more{' '}
                </a>
                <a
                    href="#hero"
                    className="cursor-pointer circle p-1 border border-gray-300 rounded-full"
                >
                    <div className="w-2 h-2 bg-white rounded-full"></div>
                </a>
            </div>

            <div className="w-1 h-[200px] lg:h-[400px] bg-gradient-to-t from-transparent to-purple-600 rounded-full my-2 relative">
                <div
                    className="absolute left-0 top-0 w-full h-full bg-gradient-to-t from-blue-500 to-transparent transition-transform"
                    style={{ transform: `translateY(${scrollPosition}px)` }}
                ></div>
            </div>
            <div className="circle p-1 border border-gray-300 rounded-full">
                <div className="w-2 h-2 bg-white rounded-full"></div>
            </div>
            <div className="relative my-3 text-white">
                <svg
                    fill="#fff"
                    aria-hidden="true"
                    height="24"
                    viewBox="0 0 24 24"
                    version="1.1"
                    width="24"
                    className="octicon octicon-code"
                >
                    <path d="M15.22 4.97a.75.75 0 0 1 1.06 0l6.5 6.5a.75.75 0 0 1 0 1.06l-6.5 6.5a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L21.19 12l-5.97-5.97a.75.75 0 0 1 0-1.06Zm-6.44 0a.75.75 0 0 1 0 1.06L2.81 12l5.97 5.97a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215l-6.5-6.5a.75.75 0 0 1 0-1.06l6.5-6.5a.75.75 0 0 1 1.06 0Z"></path>
                </svg>
                <div
                    className="absolute left-0 top-0 w-full h-full rounded-full"
                    style={{
                        backgroundColor: 'var(--mktg-accent-primary)',
                        filter: 'blur(18px)'
                    }}
                ></div>
            </div>

            <div className="w-1 h-full bg-gradient-to-b from-purple-600 via-green-600 to-green-500 rounded-full my-2"></div>
        </div>
    );
};
