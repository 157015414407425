import gql from 'graphql-tag';
import {
    graph,
    jira,
    leaderboard,
    leaderboardEntry,
    loadingStatus,
    project,
    proposal,
    recommended_job_roles,
    recommended_tech_stack,
    sow,
    thumbsRating,
    userProfile
} from '../helper';

export const GET_USER_SUBSCRIPTION = gql`
    query userSubscriptionStatus {
        userSubscriptionStatus {
            tier
            status
            created_at
            current_period_end
            duration
            current_period_start
            price
            cancel_at_period_end
        }
    }
`;

export const GET_PROJECT_LOADING_STATUS = gql`
	query loadingStatusByProject($projectId: ID!) {
		loadingStatusByProject(projectId: $projectId) {
			${loadingStatus}
		}
	}
`;

export const GET_SOW_STATUSES = gql`
    query sowStatuses($projectId: ID!) {
        sowStatuses(projectId: $projectId) {
            sow_mvp_up_to_date
            sow_custom_up_to_date
        }
    }
`;

export const GET_SOW = gql`
    query getSow($projectId: ID!, $type: String!, $version: Int) {
        getSow(projectId: $projectId, type: $type, version: $version) {
            ${sow}
        }
    }
`;

export const GET_THUMBS_RATING = gql`
    query getThumbsRating($id: String!) {
        getThumbsRating(id: $id) {
            ${thumbsRating}
        }
    }
`;

export const message = `
index
content
role
userEmail
datetime
completed
username
image_url
fileKeyS3
tagged
nodeId
`;

export const GET_UIVIEW = gql`
    query getUIView($projectId: ID!, $nodeId: ID!, $version: Int, $forceNew: Boolean, $image_url: String) {
        getUIView(projectId: $projectId, nodeId: $nodeId, version: $version, forceNew: $forceNew, image_url: $image_url) {
            _id
            created
            projectId
            nodeId
            chatHistory {
                ${message}
            }
            files {
                _id
                created
                projectId
                nodeId
                uiViewId
                code
                path
                version
                lastVersion
                lastUpdated
            }
            version
            tested
            passed  
            fileKeyScreenshot
            status
            currentPlan
        }
    }
`;

export const GET_UIVIEWS = gql`
    query getUIViews($projectId: ID!, $nodeId: ID, $version: Int) {
        getUIViews(projectId: $projectId, nodeId: $nodeId, version: $version) {
            routingTable
            views {
                _id
                created
                projectId
                nodeId
                chatHistory {
                    ${message}
                }
                files {
                    _id
                    created
                    projectId
                    nodeId
                    uiViewId
                    code
                    path
                    version
                    lastUpdated
                }
                version
                tested
                passed  
                fileKeyScreenshot
                status
            }
        }
    }
`;

export const GET_JIRA = gql`
    query getJira($projectId: ID!) {
        getJira(projectId: $projectId) {
            ${jira}
        }
    }
`;

export const GET_LINEAR = gql`
    query getLinear($projectId: ID!) {
        getLinear(projectId: $projectId) {
            ${jira}
        }
    }
`;

export const GET_PROJECTS_BY_USER = gql`
    query GetProjectsByUser($limit: Int!, $skip: Int!, $searchQuery: String) {
        projectsByUser(limit: $limit, skip: $skip, searchQuery: $searchQuery) {
            projects {
                _id
                type
                name
                budget
                updated
                created
                matchWithDev
                numProposals
                proposals {
                    ${proposal}
                }
            }
            totalProjects
        }
    }
`;

export const GET_PROJECT_BY_USER = gql`
	query GetProjectByUser($projectId: ID!) {
		projectByUser(projectId: $projectId) {
			${project}
		}
	}
`;

export const GET_PROJECT_BY_LINK = gql`
	query GetProjectByUser($projectId: ID!) {
		projectByLink(projectId: $projectId) {
			${project}
		}
	}
`;

export const RECOMMENDED_TECH_STACK_BY_VERSION = gql`
	query recommendedTechStackByVersion($projectId: String!, $version: Int!) {
		recommendedTechStackByVersion(projectId: $projectId, version: $version) {
			${recommended_tech_stack}
		}
	}
`;

export const RECOMMENDED_JOB_ROLES_BY_VERSION = gql`
	query recommendedJobRolesByVersion($projectId: String!, $version: Int!) {
		recommendedJobRolesByVersion(projectId: $projectId, version: $version) {
			${recommended_job_roles}
		}
	}
`;

export const GRAPHS_BY_VERSION = gql`
    query graphsByVersion($projectId: String!, $version: Int!) {
        graphsByVersion(projectId: $projectId, version: $version) {
            ${graph}
        }
    }
`;

export const USER_STRIPE_LINK = gql`
    query userStripeLink {
        userStripeLink
    }
`;

export const GET_SOLO_SUBSCRIPTION_LINKS = gql`
    query getSoloSubscriptionLinks {
        getSoloSubscriptionLinks {
            trial
            url
            tier
            price
            priceId
            productId
            duration
        }
    }
`;

export const GET_WEEKLY_LEADERBOARD = gql`
    query getWeeklyLeaderboard(
        $limit: Int!
        $skip: Int!
        $startingDate: DateTime
        $sortField: String
        $sortDesc: Boolean
    ) {
        getWeeklyLeaderboard(limit: $limit, skip: $skip, startingDate: $startingDate, sortField: $sortField, sortDesc: $sortDesc) {
            ${leaderboard}
        }
    }
`;

export const GET_MONTHLY_LEADERBOARD = gql`
    query getMonthlyLeaderboard(
        $limit: Int!
        $skip: Int!
        $startingDate: DateTime
        $sortField: String
        $sortDesc: Boolean
    ) {
        getMonthlyLeaderboard(limit: $limit, skip: $skip, startingDate: $startingDate, sortField: $sortField, sortDesc: $sortDesc) {
            ${leaderboard}
        }
    }
`;

export const GET_ALL_TIME_LEADERBOARD = gql`
    query getAllTimeLeaderboard($limit: Int!, $skip: Int!, $sortField: String, $sortDesc: Boolean) {
        getAllTimeLeaderboard(limit: $limit, skip: $skip, sortField: $sortField, sortDesc: $sortDesc) {
            ${leaderboard}
        }
    }
`;

export const GET_LEADERBOARD_ENTRY = gql`
    query getLeaderboardEntry($leaderboardEntryId: ID!) {
        getLeaderboardEntry(leaderboardEntryId: $leaderboardEntryId) {
            ${leaderboardEntry}
        }
    }
`;

export const GET_PROJECT_DESCRIPTION = gql`
    query getProjectDescription($projectId: ID!, $randId: ID!) {
        getProjectDescription(projectId: $projectId, randId: $randId)
    }
`;

export const GET_LEADERBOARD_ENTRY_FOR_PROJECT = gql`
    query getLeaderboardEntryForProject($projectId: ID!) {
        getLeaderboardEntryForProject(projectId: $projectId) {
            ${leaderboardEntry}
        }
    }
`;

export const GET_CHAT_HISTORY = gql`
    query getChatHistory($projectId: ID!, $skip: Int!, $limit: Int!) {
        getChatHistory(projectId: $projectId, skip: $skip, limit: $limit) {
            ${message}
        }
    }
`;

export const GET_USER_PROFILE = gql`
    query getUserProfile {
        getUserProfile {
            ${userProfile}
        }
    }
`;

export const GET_LINKEDIN_AUTH_URL = gql`
    query getLinkedInAuthUrl {
        getLinkedInAuthUrl
    }
`;

export const EXCHANGE_LINKEDIN_CODE_FOR_ACCESS_TOKEN = gql`
    query exchangeLinkedInCodeForAccessToken($code: String!, $state: String!) {
        exchangeLinkedInCodeForAccessToken(code: $code, state: $state)
    }
`;
