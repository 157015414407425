import { GENERATE_SOW_CUSTOM } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { setSow } from '../../reducers/generic/sow';
import { getSowStatuses } from './getSowStatuses';
import {
    addToSowVersions,
    setSowVersion,
    setSowVersionLoading
} from '../../reducers/generic/project';

export const generateSowCustom = (projectId, type) => async dispatch => {
    try {
        dispatch(
            setSow({
                type
            })
        );
        dispatch(setSowVersionLoading({ type, loading: true }));

        const { data } = await client.mutate({
            mutation: GENERATE_SOW_CUSTOM,
            variables: { projectId, type },
            fetchPolicy: 'network-only'
        });

        if (data?.generateSowCustom) {
            dispatch(setSow(data.generateSowCustom));
            dispatch(
                setSowVersion({ type, version: data.generateSowCustom.version })
            );
            dispatch(
                addToSowVersions({
                    type,
                    version: data.generateSowCustom.version
                })
            );
        }

        dispatch(getSowStatuses(projectId));
        dispatch(setSowVersionLoading({ type, loading: false }));
        return true;
    } catch (error) {
        console.log({ error });
        if (error.message.includes(`API is temporarily unavailable.`)) {
            alert(`API is temporarily unavailable. Please try again later.`);
        }
        if (error.message.includes('Upload a documentation format')) {
            alert('Upload a documentation format in the Knowledge Base first');
        }
        dispatch(setSowVersionLoading({ type, loading: false }));
        return false;
    }
};
