import RadialGradient from '../Components/magicui/RadialGradient';
import { ParticlesEffect } from '../Landing/HeroExperience/ParticlesEffect';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { PDFViewer } from './pdf';

export const DevAgencyLanding = () => {
    // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // if (isMobile) {
    //     window.location.href = '/pre.dev-dev-agency.pdf';
    //     return null;
    // }

    return (
        <div className="bg-black mt-[57px] p-4 w-full h-[calc(100vh-57px)] ">
            <div className="max-w-screen-xl mx-auto h-full border-8 border-gray-300/80 rounded-xl shadow-2xl">
                <ParticlesEffect />
                <div className="flex flex-col items-center justify-center h-full">
                    {/* <iframe
                          src="/pre.dev-dev-agency.pdf"
                          title="Pre.Dev Dev Agency"
                          className="w-full h-full"
                      ></iframe> */}
                    <PDFViewer />
                </div>
                <RadialGradient />
            </div>
        </div>
    );
};
