import { useRef, useState } from 'react';
import { Suggest } from './Suggest';
import { SkipQuestions } from './SkipQuestions';
import { AttachFile } from './AttachFile';
import { Input } from './Input';
import { PricingEstimate } from '../../Docs/EditorHeader/PricingEstimate';
import { LeaderboardInput } from './LeaderboardInput';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { selectProject } from '../../../../redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import { LeadGenToggle } from '../../../EnterpriseHome/ClientProjects/ProjectsList/LeadGenToggle';
import { LeadGenData } from './LeadGenData';
import { BudgetButton } from './BudgetButton';
export const ChatInput = ({ isWidget, setShowDemoBanner }) => {
    const textareaRef = useRef(null);
    const [answer, setAnswer] = useState('');
    const [image_url, setImageUrl] = useState(null);
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [file, setFile] = useState(null);
    const [isUploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const { isHomePage, isSolo, isShare } = useSubscriptionPayment();
    const project = useSelector(selectProject);

    return (
        <div
            id="chatInput"
            className="max-w-3xl mx-auto backdrop-blur-md dark relative pb-1 w-full"
        >
            <div class="mt-2 flex w-full gap-x-4 whitespace-nowrap text-xs text-slate-600 dark:text-slate-300 sm:text-sm">
                <LeadGenData />
                <Suggest
                    setShowDemoBanner={setShowDemoBanner}
                    isShare={isShare}
                    textareaRef={textareaRef}
                    setAnswer={setAnswer}
                    answer={answer}
                />

                <SkipQuestions isWidget={isWidget} />
                <div className="md:hidden">
                    <BudgetButton />
                </div>
                {/* {(isSolo || isHomePage) && <LeaderboardInput showText={true} />} */}
            </div>
            <LeadGenToggle project={project} textColor="text-gray-400" />

            <Input
                isShare={isShare}
                isSolo={isSolo}
                isWidget={isWidget}
                image_url={image_url}
                setImageUrl={setImageUrl}
                fileKeyS3={fileKeyS3}
                setFileKeyS3={setFileKeyS3}
                setFile={setFile}
                setProgress={setProgress}
                isUploading={isUploading}
                setUploading={setUploading}
                progress={progress}
                answer={answer}
                file={file}
                setAnswer={setAnswer}
                textareaRef={textareaRef}
                setShowDemoBanner={setShowDemoBanner}
            />

            <p className={`text-gray-500 text-2xs ml-2 mt-2`}>
                Don't know how to answer? Try clicking <b>Answer for me</b>.
            </p>
        </div>
    );
};
