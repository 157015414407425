import { VOTE_ON_LEADERBOARD_ENTRY } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';
import {
    setLeaderboardEntry,
    setLeaderboardProjectEntry,
    upvoteLeaderboard
} from '../../reducers/leaderboard';

export const voteOnLeaderboardEntry =
    (leaderboardEntryId: string, remove: boolean) => async (dispatch: any) => {
        try {
            const { data } = await client.mutate({
                mutation: VOTE_ON_LEADERBOARD_ENTRY,
                variables: {
                    leaderboardEntryId,
                    remove
                },
                fetchPolicy: 'network-only'
            });

            dispatch(setLeaderboardEntry(data.voteOnLeaderboardEntry));
            dispatch(setLeaderboardProjectEntry(data.voteOnLeaderboardEntry));
            dispatch(
                upvoteLeaderboard({
                    leaderboardEntryId,
                    remove
                })
            );

            return 'Successfully voted on leaderboard entry.';
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
