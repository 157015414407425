import { GET_MONTHLY_LEADERBOARD } from '../../../api/graphQL/solo/query';
import { client } from '../../../api/client';
import {
    fetchLeaderboardListStart,
    setLeaderboardList,
    setPaginateLoading
} from '../../reducers/leaderboard';

export const getMonthlyLeaderboard =
    (
        limit: number,
        skip: number,
        sortField?: string,
        sortDesc?: boolean,
        paginate?: boolean
    ) =>
    async (dispatch: any, getState: any) => {
        try {
            if (paginate) dispatch(setPaginateLoading(true));
            else dispatch(fetchLeaderboardListStart());

            const { data } = await client.query({
                query: GET_MONTHLY_LEADERBOARD,
                variables: {
                    limit,
                    skip,
                    sortField,
                    sortDesc
                },
                fetchPolicy: 'network-only'
            });

            if (paginate) {
                const entries = data.getMonthlyLeaderboard.entries;
                const allEntries = [
                    ...(getState().leaderboard.list || []),
                    ...entries
                ];
                dispatch(
                    setLeaderboardList({
                        ...data.getMonthlyLeaderboard,
                        entries: allEntries
                    })
                );
                dispatch(setPaginateLoading(false));
            } else {
                dispatch(setLeaderboardList(data.getMonthlyLeaderboard));
            }

            return 'Successfully fetched monthly leaderboard.';
        } catch (error) {
            console.error({ error });
            return error.message;
        }
    };
