import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { MatchWithDev } from '../../ProjectDashboard/Budget/matchWithDev';
import { useProject } from '../../../context-providers/Project';
import { updateMatchWithDev } from '../../../redux/actions/solo/updateMatchWithDev';
import { useDispatch } from 'react-redux';
import { Fireworks } from '@fireworks-js/react';
import { motion } from 'framer-motion';

export const MatchModal = () => {
    const { projectId } = useParams();
    const { project } = useProject();
    const [showMatchModal, setShowMatchModal] = useState(false);
    const [matchLoading, setMatchLoading] = useState(false);
    const [hasModalBeenShown, setHasModalBeenShown] = useState(false);
    const matchWithDev = project?.matchWithDev;
    const dispatch = useDispatch();

    const setMatch = async val => {
        setMatchLoading(true);
        await dispatch(updateMatchWithDev(projectId, val));
        setMatchLoading(false);
    };

    useEffect(() => {
        setTimeout(() => {
            if (
                projectId &&
                project &&
                !project.matchWithDev &&
                !hasModalBeenShown
            ) {
                setShowMatchModal(true);
                setHasModalBeenShown(true);
            }
        }, 10000);
    }, [projectId, project, hasModalBeenShown]);

    return (
        <>
            <Modal
                open={showMatchModal}
                onClose={() => setShowMatchModal(false)}
                title="🎉 Congratulations on Starting Your Project!"
                bgColor="bg-gradient-to-br from-black to-blue-900"
                titleColor="text-white"
                size="lg"
            >
                <div className="relative">
                    <div className="relative z-10 p-6 text-left">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-3xl font-bold text-white mb-4">
                                Your Project is Ready!
                            </h2>
                            <p className="text-blue-200 text-md mb-8">
                                You've taken the first step towards building
                                something amazing. <br />
                                <br />
                                Now, let's match you with the perfect developer
                                to bring your vision to life.
                            </p>
                        </motion.div>

                        <MatchWithDev
                            matchWithDev={matchWithDev}
                            setMatchWithDev={setMatch}
                            matchLoading={matchLoading}
                        />
                    </div>
                </div>
            </Modal>
            {showMatchModal && (
                <Fireworks
                    options={{
                        rocketsPoint: {
                            min: 0,
                            max: 100
                        }
                    }}
                    style={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        zIndex: 0
                    }}
                />
            )}
        </>
    );
};
