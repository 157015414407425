import { useSelector, useDispatch } from 'react-redux';
import {
    selectBudgetFilterActivated,
    setBudgetFilterActivated
} from '../../../redux/reducers/generic/sow';

export const FilterByBudget = () => {
    const dispatch = useDispatch();
    const budgetFilterActivated = useSelector(selectBudgetFilterActivated);

    const handleFilterChange = e => {
        dispatch(setBudgetFilterActivated(e.target.checked));
    };

    return (
        <div className="mt-2 flex flex-col items-center space-x-2">
            <div className="flex items-center">
                <input
                    type="checkbox"
                    id="filterByBudget"
                    checked={budgetFilterActivated}
                    onChange={handleFilterChange}
                    className="mr-2 cursor-pointer"
                />
                <label
                    htmlFor="filterByBudget"
                    className="text-xs text-gray-200 cursor-pointer"
                >
                    Filter out low priority tasks that exceeed your budget
                </label>
            </div>
        </div>
    );
};
