import { cn } from '../Components/magicui/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { AlignJustify, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UseCases } from '../UseCases';
import {
    salesCalendlyLink,
    setRedirectURL,
    talkToPreDev
} from '../../constants';
import { Whitelabel } from './Whitelabel';
import { useEnterpriseOrganizations } from '../../context-providers/EnterpriseOrganizations';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { selectShowRequestModal } from '../../redux/reducers/generic/project';
import { useSelector } from 'react-redux';

const menuItems = [
    // { id: 1, label: 'Leaderboard', href: '/leaderboard' },
    { id: 2, label: 'How It Works', href: '/how-it-works' },
    { id: 3, label: 'Pricing', href: '/#pricing' },
    { id: 4, label: 'Login' },
    { id: 5, label: 'Signup' },
    { id: 6, label: 'Contact Us', href: talkToPreDev }
];

const Logo = () => (
    <a href="/" className="flex items-center lg:pl-10">
        <img src="/predev.png" className="h-6" alt="Company Logo" />
        <span className="ml-2 self-center text-lg whitespace-nowrap text-white">
            pre.dev
        </span>
    </a>
);

const NavLinks = () => {
    let navigate = useNavigate();
    return (
        <div className="hidden lg:flex gap-x-8 items-center">
            <a
                onClick={() => navigate('/#how-it-works')}
                className="cursor-pointer text-white text-sm"
            >
                How It Works
            </a>
            <a
                onClick={() => navigate('/#pricing')}
                className="cursor-pointer text-white text-sm"
            >
                Pricing
            </a>
            <a
                onClick={() => window.open(talkToPreDev, '_blank')}
                className="cursor-pointer text-white text-sm hidden xl:block"
            >
                Contact
            </a>
        </div>
    );
};

const LeaderboardButton = () => (
    <Link
        to="/leaderboard"
        className="hidden sm:mr-4 lg:mr-0 sm:flex items-center justify-center px-4 py-2 rounded-lg bg-gradient-to-r from-blue-500 to-blue-700 text-white text-sm font-bold shadow-lg transition duration-300 transform hover:scale-105 hover:from-blue-600 hover:to-blue-800"
    >
        Leaderboard
    </Link>
);

const LoginSection = ({ onGetStarted, sessionContext }) => {
    if (sessionContext.loading) return null;

    return sessionContext?.doesSessionExist ? (
        <a
            onClick={onGetStarted}
            className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-gray-800 text-white shadow-sm hover:bg-gray-700 h-9 px-4 py-2 mr-6 text-sm"
        >
            Dashboard
        </a>
    ) : (
        <div className="flex items-center gap-x-8">
            <a
                onClick={onGetStarted}
                className="cursor-pointer text-white text-sm"
            >
                Log in
            </a>
            <a
                onClick={onGetStarted}
                className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-gray-800 text-white shadow-sm hover:bg-gray-700 h-9 px-4 py-2 mr-6 text-sm"
            >
                Sign up
            </a>
        </div>
    );
};

const HamburgerButton = ({ isOpen, onClick }) => (
    <button className="lg:hidden text-white" onClick={onClick}>
        <span className="sr-only">Toggle menu</span>
        {isOpen ? <XIcon className="text-white" /> : <AlignJustify />}
    </button>
);

const MobileNav = ({ isOpen, onClose, onGetStarted }) => (
    <AnimatePresence>
        <motion.nav
            initial="initial"
            exit="exit"
            variants={{
                initial: { opacity: 0, scale: 1 },
                animate: {
                    scale: 1,
                    opacity: 1,
                    transition: { duration: 0.2, ease: 'easeOut' }
                },
                exit: {
                    opacity: 0,
                    transition: { duration: 0.2, delay: 0.2, ease: 'easeOut' }
                }
            }}
            animate={isOpen ? 'animate' : 'exit'}
            className={cn(
                'fixed left-0 top-0 z-50 h-screen w-full overflow-auto bg-background/70 bg-black backdrop-blur-[12px]',
                {
                    'pointer-events-none': !isOpen
                }
            )}
        >
            <div className="container flex h-[3.5rem] items-center justify-between">
                <a className="text-md flex items-center" href="/"></a>
                <HamburgerButton isOpen={isOpen} onClick={onClose} />
            </div>
            <div className="flex w-full flex-row items-center justify-center gap-x-4 pb-4 -mt-8">
                {/* <UseCases /> */}
                {/* <Whitelabel /> */}
            </div>
            <motion.ul
                className="flex flex-col uppercase"
                variants={{ open: { transition: { staggerChildren: 0.06 } } }}
                initial="initial"
                animate={isOpen ? 'open' : 'exit'}
            >
                {menuItems.map(item => (
                    <motion.li
                        key={item.id}
                        className="cursor-pointer border-grey-dark pl-6 py-0.5 border-b md:border-none"
                        variants={{
                            initial: { y: '-20px', opacity: 0 },
                            open: {
                                y: 0,
                                opacity: 1,
                                transition: { duration: 0.3, ease: 'easeOut' }
                            }
                        }}
                    >
                        <Link
                            className="text-white hover:text-grey flex h-[var(--navigation-height)] w-full items-center text-xl transition-[color,transform] duration-300"
                            to={item.href}
                            onClick={!item.href ? onGetStarted : onClose}
                        >
                            {item.label}
                        </Link>
                    </motion.li>
                ))}
            </motion.ul>
        </motion.nav>
    </AnimatePresence>
);

export function SiteHeader() {
    const { enterpriseOrganizations } = useEnterpriseOrganizations();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const sessionContext = useSessionContext();
    const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false);

    const onGetStarted = () => {
        let redirectURL = '/projects';
        if (enterpriseOrganizations.length > 0) {
            redirectURL = '/enterprise/dashboard';
        }
        setRedirectURL(redirectURL);
        if (sessionContext.doesSessionExist === true) {
            navigate(redirectURL);
        } else {
            navigate('/auth/?redirectToPath=%2Fprojects');
        }
    };

    useEffect(() => {
        const html = document.querySelector('html');
        if (html) html.classList.toggle('overflow-hidden', hamburgerMenuIsOpen);
    }, [hamburgerMenuIsOpen]);

    useEffect(() => {
        const closeHamburgerNavigation = () => setHamburgerMenuIsOpen(false);
        window.addEventListener('orientationchange', closeHamburgerNavigation);
        window.addEventListener('resize', closeHamburgerNavigation);

        return () => {
            window.removeEventListener(
                'orientationchange',
                closeHamburgerNavigation
            );
            window.removeEventListener('resize', closeHamburgerNavigation);
        };
    }, []);

    const requestModalOpen = useSelector(selectShowRequestModal);

    return (
        <div className="max-w-screen-xl mx-auto">
            <header
                className={`bg-black fixed left-0 top-0 ${
                    !requestModalOpen ? 'z-20' : ''
                } w-full border-b border-gray-800 backdrop-blur-[12px]`}
            >
                <div className="container flex h-[3.5rem] items-center justify-between">
                    <div className="flex items-center gap-x-8">
                        <Logo />
                        <div className="hidden md:flex gap-x-4">
                            {/* <UseCases /> */}
                            {/* <Whitelabel /> */}
                        </div>
                    </div>

                    <div className="flex items-center ml-auto lg:gap-x-8">
                        <NavLinks />
                        {/* <LeaderboardButton /> */}
                        <LoginSection
                            onGetStarted={onGetStarted}
                            sessionContext={sessionContext}
                        />
                        <HamburgerButton
                            isOpen={hamburgerMenuIsOpen}
                            onClick={() =>
                                setHamburgerMenuIsOpen(open => !open)
                            }
                        />
                    </div>
                </div>
            </header>
            <MobileNav
                isOpen={hamburgerMenuIsOpen}
                onClose={() => setHamburgerMenuIsOpen(false)}
                onGetStarted={onGetStarted}
            />
        </div>
    );
}
