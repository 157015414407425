import { useState, useRef } from 'react';
import { BentoCard, BentoGrid } from '../../Components/magicui/bento-grid';
import { GenerateJiraLinearRoadmaps } from './GenerateJiraLinearRoadmaps';
import { GuidedProjectDiscovery } from './GuidedProjectDiscovery';
import { FinedTunedPlanningAgent } from './FinedTunedPlanningAgent';
import { ArchitectureGeneration } from './ArchitectureGeneration';
import { UIUXAcceleratorCodeGen } from './UIUXAcceleratorCodeGen';
import { ProductDocumentation } from './ProductDocumentation';
import { MatchWithDev } from './MatchWithDev';

export function FeaturesSection() {
    const fgRef = useRef(null);

    const features = ({ fgRef }) => {
        return [
            FinedTunedPlanningAgent(),
            GuidedProjectDiscovery(),
            ArchitectureGeneration(),
            ProductDocumentation(),
            MatchWithDev(),
            UIUXAcceleratorCodeGen()
        ];
    };

    const [feats, setFeats] = useState(features({ fgRef }));

    return (
        <div id="how-it-works" className="py-20 px-8 dark">
            <h2 className="text-3xl text-white font-bold mb-2 w-full text-center">
                How it works
            </h2>
            <p className="text-lg text-gray-600 w-full text-center">
                Don't let your project get bogged down by the complexities of
                software development.
            </p>
            <BentoGrid>
                {feats.map((feature, idx) => (
                    <BentoCard key={idx} {...feature} />
                ))}
            </BentoGrid>
            <GenerateJiraLinearRoadmaps />
        </div>
    );
}
